import getStoredAuthWorkspaceName from './getStoredAuthWorkspaceName';
import setStoredAuthWorkspaceName from './setStoredAuthWorkspaceName';

const clearStorage = () => {
	/**
	 * Clear local storage, but preserve things that should remain\
	 * We need to preserve the workspace name in local storage because it is used during login
	 */
	const workspaceName = getStoredAuthWorkspaceName();
	localStorage.clear();
	if (workspaceName != null) {
		setStoredAuthWorkspaceName(workspaceName);
	}

	// clear session storage
	sessionStorage.clear();
};

export default clearStorage;
