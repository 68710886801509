import type { Notification } from '@storis/app_common.components';
import { Box, Button, FormLabel, Typography } from '@storis/app_common.ui/components';
import { useMemo, useState } from 'react';
import { BotDetection } from '#internal/components/BotDetection';
import { useBotDetection } from '#internal/hooks/useBotDetection';
import SignInCard from '../SignInCard';
import { useInitiatePasswordReset } from '../useInitiatePasswordReset';

interface PasswordEmailSentCardProps {
	email: string;
	turnstileSiteKey: string;
	workspaceName: string;
	createNotification: (payload: Notification) => void;
}

const PasswordEmailSentCard = (props: PasswordEmailSentCardProps) => {
	const { email, turnstileSiteKey, workspaceName, createNotification } = props;

	const [isSubmitted, setIsSubmitted] = useState(false);

	const {
		status: botDetectionStatus,
		errorCode,
		challengeResponse,
		isInteractive,
		handleSuccess: handleChallengeSuccess,
		handleError: handleChallengeError,
		handleExpire: handleChallengeExpire,
		handleBeforeInteractive,
	} = useBotDetection();

	const [initiatePasswordReset, { loading }] = useInitiatePasswordReset();

	const handleSubmit = () => {
		setIsSubmitted(true);
		if (challengeResponse != null) {
			initiatePasswordReset({ variables: { workspaceName, userEmail: email, challengeResponse } })
				.then(() => {
					createNotification({ text: 'Email Sent' });
				})
				.catch(() => {});
		}
	};

	const errorMessage = useMemo(() => {
		if (botDetectionStatus === 'error') {
			return `Bot verification failed (Error: ${errorCode})`;
		}
		return null;
	}, [botDetectionStatus, errorCode]);

	return (
		<SignInCard title="Check your email" loading={loading}>
			{errorMessage != null && <FormLabel error>{errorMessage}</FormLabel>}
			<Typography>
				We have sent an email to <strong>{email}</strong> with instructions on how to create a new
				password.
			</Typography>
			<Typography>If you didn&apos;t get the email, try sending it again.</Typography>
			<BotDetection
				appearance="always"
				isInteractive={isInteractive}
				siteKey={turnstileSiteKey}
				isSubmitted={isSubmitted}
				botDetectionStatus={botDetectionStatus}
				onSuccess={handleChallengeSuccess}
				onError={handleChallengeError}
				onExpire={handleChallengeExpire}
				onBeforeInteractive={handleBeforeInteractive}
			/>
			<Box>
				<Button onClick={handleSubmit} disabled={botDetectionStatus === 'pending' || loading}>
					Resend Email
				</Button>
			</Box>
		</SignInCard>
	);
};

export default PasswordEmailSentCard;
