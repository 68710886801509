import { gql } from '@apollo/client';
import { useWorkspaceFeatures_MeFragment } from './fragments.gql';

export const GetMeWorkspaceFeatures = gql`
	query GetMeWorkspaceFeatures {
		me {
			id
			...useWorkspaceFeatures_MeFragment
		}
	}

	${useWorkspaceFeatures_MeFragment}
`;
