import { gql } from '@apollo/client';

export const InitiatePasswordReset = gql`
	mutation InitiatePasswordReset(
		$userEmail: String!
		$workspaceName: String!
		$challengeResponse: String!
	) {
		userInitiatePasswordReset(
			userEmail: $userEmail
			workspaceName: $workspaceName
			challengeResponse: $challengeResponse
		)
	}
`;
