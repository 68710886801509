import { gql } from '@apollo/client';

export const CancelPasswordReset = gql`
	mutation CancelPasswordReset(
		$workspaceId: String!
		$requestId: String!
		$token: String!
		$challengeResponse: String!
	) {
		userCancelPasswordReset(
			workspaceId: $workspaceId
			requestId: $requestId
			token: $token
			challengeResponse: $challengeResponse
		)
	}
`;
