export interface HomeEnvironment {
	environment: 'development' | 'qa' | 'staging' | 'production';
	graphqlApi: string;
	turnstileSiteKey: string;
	entraIdClientId: string;
	entraIdAuthority: string;
	entraIdRedirectUrl: string;
}

declare const env: HomeEnvironment;

const getEnv = (): HomeEnvironment => {
	return {
		environment: env.environment,
		graphqlApi: env.graphqlApi,
		turnstileSiteKey: env.turnstileSiteKey,
		entraIdClientId: env.entraIdClientId,
		entraIdAuthority: env.entraIdAuthority,
		entraIdRedirectUrl: env.entraIdRedirectUrl,
	};
};

export default getEnv;
