import { Box, Card, CardActionArea, Stack, Typography } from '@storis/app_common.ui/components';
import type { SvgIconProps } from '@storis/app_common.ui/icons';

interface AppCardProps {
	title: string;
	description: string;
	path: string;
	icon: React.ElementType<SvgIconProps>;
}

const AppCard = (props: AppCardProps) => {
	const { title, description, path, icon: Icon } = props;

	return (
		<Card>
			<CardActionArea href={path} sx={{ p: 2, ':hover': { textDecoration: 'none' } }}>
				<Stack direction="row" spacing={2}>
					<Box
						sx={{
							p: 2,
							border: '1px solid',
							borderColor: (theme) => theme.palette.divider,
							borderRadius: 1,
						}}
					>
						<Icon sx={{ fontSize: 46 }} color="secondary" />
					</Box>
					<Stack spacing={1} justifyContent="center">
						<Typography variant="h6" component="h3">
							{title}
						</Typography>
						<Typography
							variant="body2"
							color="text.secondary"
							sx={{
								// visible on sm and above screens
								display: { xs: 'none', sm: 'block' },
							}}
						>
							{description}
						</Typography>
					</Stack>
				</Stack>
				<Typography
					variant="body2"
					color="text.secondary"
					sx={{
						mt: 1.5,
						// visible on xs screens only
						display: { sm: 'none' },
					}}
				>
					{description}
				</Typography>
			</CardActionArea>
		</Card>
	);
};

export default AppCard;
