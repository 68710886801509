import { isAcceptable, isStale } from '@storis/app_common.utils/jwt';
import { useRefreshToken } from '@storis/app_common.utils/storage';
import { redirectWithHash } from '@storis/app_common.utils/uri';
import { useEffect, useRef } from 'react';

interface UseRefreshTokenChangeParams {
	redirectPath: string;
}

/**
 * This hook is used to handle changes to the refresh token\
 * If the refresh token is no longer valid, the user is signed out
 */
const useRefreshTokenChange = (params: UseRefreshTokenChangeParams) => {
	const { redirectPath } = params;

	// default to null because when it flips between null and undefined with multiple tabs open it causes an infinite loop
	const [refreshToken = null] = useRefreshToken();
	const isValidRefreshToken =
		refreshToken != null && isAcceptable(refreshToken) && !isStale(refreshToken);
	const hadValidRefreshToken = useRef(false);
	useEffect(() => {
		if (isValidRefreshToken) {
			hadValidRefreshToken.current = true;
		}
		if (hadValidRefreshToken.current && !isValidRefreshToken) {
			// If we don't have a good refresh token, the user is unauthorized. Sign out.
			redirectWithHash({ url: redirectPath, notification: "You've been signed out." });
		}
	}, [isValidRefreshToken, redirectPath, refreshToken]);
};

export default useRefreshTokenChange;
