import { useCallback } from 'react';
import createPersistedState from './createPersistedState';
import { accessTokenKey } from './keys';
import setStoredClockDriftFromToken from './setStoredClockDriftFromToken';

const useAccessTokenState = createPersistedState<string | null>(accessTokenKey);

/**
 * Hook that returns the currently stored access token and a function to update it\
 * This is the preferred method for accessing and setting the access token in React components and hooks
 */
const useAccessToken: typeof useAccessTokenState = () => {
	const [accessToken, setPersistedState] = useAccessTokenState();

	const customSetState: typeof setPersistedState = useCallback(
		(newValue) => {
			setStoredClockDriftFromToken(newValue);
			setPersistedState(newValue);
		},
		[setPersistedState],
	);

	return [accessToken, customSetState];
};

export default useAccessToken;
