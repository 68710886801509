import { useIsMobile } from '@storis/app_common.hooks';
import { Box, LinearProgress, Typography } from '@storis/app_common.ui/components';

const Loading = () => (
	<>
		<Typography variant="h5" component="p" sx={{ m: 2, marginBottom: '16px' }}>
			Loading...
		</Typography>
		<LinearProgress />
	</>
);

interface ExternalPageLayoutProps {
	children: React.ReactNode;
	/**
	 * `isLoading` (default `false`)\
	 * If `true` a progress title (Loading...) and a linear progress bar will be presented. The children will not be
	 * rendered until `isLoading` is `false`.
	 */
	isLoading?: boolean;
}

/** A layout component used for pages outside of the logged-in apps. */
const ExternalPageLayout = (props: ExternalPageLayoutProps) => {
	const { children, isLoading } = props;
	const isMobile = useIsMobile();

	if (isLoading) {
		return <Loading />;
	}

	return (
		<Box sx={{ px: isMobile ? 2 : 3, pt: isMobile ? 3 : 10, pb: isMobile ? 2 : 3 }}>{children}</Box>
	);
};

export default ExternalPageLayout;
