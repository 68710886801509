import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@storis/app_common.ui/components';
import type { DialogProps } from '@storis/app_common.ui/components';
import { SaveAltIcon } from '@storis/app_common.ui/icons';
import pluralize from 'pluralize';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { CopyToClipboardButton } from '../CopyToClipboardButton';
import MoreInfoAttribute from './MoreInfoAttribute';
import MoreInfoCopyButton from './MoreInfoCopyButton';
import type { GenericObject, Notification, NotificationInformation } from './types';

interface MoreInfoDialogProps extends DialogProps {
	notification?: Notification;
	open: boolean;
	onCopiedToClipboard?: () => void;
	children?: React.ReactNode;
}

const useNotification = (notification?: Notification) => {
	const { moreInformation, text } = notification ?? {};

	return useMemo(() => {
		if (moreInformation == null) {
			// this shouldn't really happen because the purpose of this component is to display more information
			return { text };
		}

		if ((moreInformation.errors ?? []).length > 1) {
			// this dialog is only built for presenting information about a single error or response
			return { moreInformation, text };
		}

		const { name: errorName, requestId, message: errorMessage } = moreInformation;

		if (moreInformation.errors == null && moreInformation.response != null) {
			// network errors will be in the `response` property
			return {
				errorName: moreInformation.response.data.name,
				errorStatus: moreInformation.response.status,
				responseErrorMessage: moreInformation.response.data.message,
				errorMessage,
				requestId,
				moreInformation,
				text,
			};
		}

		if (moreInformation.errors?.length === 1 && moreInformation.errors[0] != null) {
			// errors from graphql will be in the `errors` property
			if ('extensions' in moreInformation.errors[0]) {
				return {
					errorMessage: moreInformation.errors[0]?.message,
					errorCode: moreInformation.errors[0]?.extensions?.code,
					errorType: moreInformation.errors[0]?.extensions?.exception?.type,
					requestId: moreInformation.errors[0]?.extensions?.requestId,
					errorName,
					moreInformation,
					text,
				};
			}

			return {
				errorMessage: moreInformation.errors[0]?.message,
				errorName,
				requestId,
				moreInformation,
				text,
			};
		}

		// all other situations will be treated as a single error
		return { errorMessage, errorName, requestId, moreInformation, text };
	}, [moreInformation, text]);
};

const useMoreInformation = (moreInformation?: NotificationInformation) => {
	const [downloadMoreInfoUrl, setDownloadMoreInfoUrl] = useState<string | null>(null);

	const moreInfoJson = useMemo(() => {
		if (moreInformation == null) {
			return undefined;
		}

		return JSON.stringify(
			moreInformation,
			(key, value) => {
				if (key === 'Authorization') {
					// network errors include the Authorization token, which should be treated as a password.
					// remove the Authorization token so that it isn't copied/exported.
					return 'REDACTED';
				}

				if (value instanceof Error) {
					return Object.getOwnPropertyNames(value).reduce(
						(errorObj: GenericObject, errorKey: string): GenericObject => {
							return { ...errorObj, [errorKey]: (value as GenericObject)[errorKey] };
						},
						{},
					);
				}

				return value;
			},
			2,
		);
	}, [moreInformation]);

	useEffect(() => {
		if (moreInfoJson != null) {
			const blob = new Blob([moreInfoJson], { type: 'application/json' });
			const newDownloadUrl = URL.createObjectURL(blob);
			setDownloadMoreInfoUrl(newDownloadUrl);
			return (): void => {
				URL.revokeObjectURL(newDownloadUrl);
			};
		}

		setDownloadMoreInfoUrl(null);
		return undefined;
	}, [moreInfoJson]);

	return useMemo(() => {
		return { downloadMoreInfoUrl, moreInfoJson };
	}, [downloadMoreInfoUrl, moreInfoJson]);
};

const MoreInfoDialog = (props: MoreInfoDialogProps) => {
	const { notification = {} as Notification, onCopiedToClipboard, children, ...other } = props;

	const {
		errorCode,
		errorMessage,
		errorName,
		errorStatus,
		errorType,
		moreInformation,
		requestId,
		responseErrorMessage,
		text,
	} = useNotification(notification);

	const { downloadMoreInfoUrl, moreInfoJson } = useMoreInformation(moreInformation);

	const errorAttribute = errorType ?? errorCode ?? errorName;
	const messageAttribute = responseErrorMessage ?? errorMessage;
	const errorCount = moreInformation?.errors ? moreInformation.errors.length : 1;

	return (
		<Dialog
			PaperProps={{ 'aria-labelledby': 'MoreInfoDialog-title' }}
			slotProps={{
				backdrop: {
					// @ts-expect-error: `data-testid` is valid for testing-library
					'data-testid': 'MoreInfoDialog-backdrop',
				},
			}}
			scroll="body"
			{...other}
		>
			<DialogTitle component="div">
				<Typography component="h2" variant="h6" id="MoreInfoDialog-title">
					More Information
				</Typography>
				<Typography component="h3" variant="subtitle1">
					{moreInformation != null && `${errorCount} ${pluralize('Error', errorCount)}`}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText component="div">
					{text != null && (
						<MoreInfoAttribute
							label="Notification"
							value={text}
							labelId="MoreInfoDialog-notification-label"
						/>
					)}
					{errorAttribute != null && (
						<MoreInfoAttribute
							label="Error"
							value={errorAttribute}
							labelId="MoreInfoDialog-error-label"
						/>
					)}
					{messageAttribute != null && (
						<MoreInfoAttribute
							label="Message"
							value={messageAttribute}
							labelId="MoreInfoDialog-message-label"
						/>
					)}
					{errorStatus != null && (
						<MoreInfoAttribute
							label="Status Code"
							value={`${errorStatus}`}
							labelId="MoreInfoDialog-status-code-label"
						/>
					)}
					{requestId != null && (
						<MoreInfoAttribute
							label="Request ID"
							value={requestId}
							labelId="MoreInfoDialog-request-id-label"
						/>
					)}
				</DialogContentText>
			</DialogContent>
			{children}
			<DialogActions>
				<CopyToClipboardButton
					value={moreInfoJson}
					component={MoreInfoCopyButton}
					IconProps={{ sx: { fontSize: 20, mr: 1 } }}
					onCopiedToClipboard={onCopiedToClipboard}
				/>
				<Button
					color="primary"
					component="a"
					href={downloadMoreInfoUrl ?? ''}
					download="cxm-error-details.json"
					data-testid="Save Anchor"
				>
					<SaveAltIcon sx={{ fontSize: 20, mr: 1 }} />
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default MoreInfoDialog;
