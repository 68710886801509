import calculateClockDriftFromToken from '../jwt/calculateClockDriftFromToken';
import { clockDriftKey } from './keys';
import setStoredItem from './setStoredItem';

/**
 * This is intentionally not exported from the index. This should be called BEFORE the new token is put into storage
 * because token values are watched/subscribed to, and clock drift is not.
 *
 * Access token & refresh token always get set together, so theoretically we only need to calculate clock drift for one
 * of them. But consumers are free to choose which token gets set first, so we need to calculate clock drift for both.
 */
const setStoredClockDriftFromToken = (token: string | null | undefined): void => {
	const clockDrift = token == null ? null : calculateClockDriftFromToken(token);
	if (clockDrift != null) {
		setStoredItem(clockDriftKey, clockDrift);
	}
};

export default setStoredClockDriftFromToken;
