import { useQuery } from '@apollo/client';
import { isAcceptable } from '@storis/app_common.utils/jwt';
import { useAccessToken } from '@storis/app_common.utils/storage';
import { WorkspacePermissionName } from '#internal/types/graphqlTypes';
import type { GetMeResult, GetMeVariables } from '#internal/types/graphqlTypes';
import { GetMe } from './queries.gql';

export enum IsWorkspaceAdminResult {
	pending,
	valid,
	invalid,
	skipped,
	error,
}

const useIsWorkspaceAdmin = (): IsWorkspaceAdminResult => {
	const [accessToken] = useAccessToken();
	const skip = accessToken == null || !isAcceptable(accessToken);

	const { data, loading, error } = useQuery<GetMeResult, GetMeVariables>(GetMe, {
		skip,
		context: { errorMessage: 'Unable to load current user' },
		fetchPolicy: 'network-only',
	});

	if (skip) {
		return IsWorkspaceAdminResult.skipped;
	}

	if (loading) {
		return IsWorkspaceAdminResult.pending;
	}

	if (error != null) {
		return IsWorkspaceAdminResult.error;
	}

	const userPermissions = data?.me?.permissions;
	if (
		userPermissions?.some(
			(permission) => permission?.name === WorkspacePermissionName.workspaceAdminister,
		)
	) {
		// workspace admins have access
		return IsWorkspaceAdminResult.valid;
	}

	return IsWorkspaceAdminResult.invalid;
};

export default useIsWorkspaceAdmin;
