import { decodeURIData } from '@storis/app_common.utils/uri';
import qs from 'qs';
import { useEffect, useRef } from 'react';
import { useRemoveUrlHash } from '../useRemoveUrlHash';

export interface AppHash {
	redirect?: string;
}

export interface AppRedirectHash {
	/** Notification to present after being redirected to login */
	notification?: string;
	/** Path to redirect to after logging in */
	path?: string;
}

interface Notification {
	text: string;
	autoHideDuration?: number | null;
}

interface UseRedirectHashNotificationParams {
	createNotification: (payload: Notification) => void;
}

/** Present a notification is the URL hash contains a `redirect` with a `notification` message */
const useRedirectHashNotification = (params: UseRedirectHashNotificationParams) => {
	const { createNotification } = params;

	const hashChecked = useRef(false);
	const removeUrlHash = useRemoveUrlHash();

	/** If the URL hash contains notification text, display a notification to the user */
	useEffect(() => {
		if (!hashChecked.current && window.location.hash.includes('redirect')) {
			hashChecked.current = true;
			try {
				const { redirect } = qs.parse(window.location.hash.substring(1)) as AppHash;
				const { notification } = decodeURIData(decodeURI(redirect ?? '')) as AppRedirectHash;

				if (notification != null) {
					createNotification({ text: notification, autoHideDuration: 10000 });
				}

				removeUrlHash();
			} catch {
				// unable to parse redirect hash
			}
		}
	}, [createNotification, removeUrlHash]);
};

export default useRedirectHashNotification;
