import { gql } from '@apollo/client';

export const useGetMeDetails_MeFragment = gql`
	fragment useGetMeDetails_MeFragment on Me {
		id
		staff {
			id
		}
		givenName
		familyName
		email
		workspace {
			id
			name
			client {
				id
			}
			country
			erpDetails {
				version
				clientNumber
				patchLevel
			}
		}
	}
`;
