import { ManageAccountsOutlinedIcon, ShoppingCartOutlinedIcon } from '@storis/app_common.ui/icons';
import type { SvgIconProps } from '@storis/app_common.ui/icons';
import { IsWorkspaceAdminResult, useIsWorkspaceAdmin } from '../useIsWorkspaceAdmin';
import { useWorkspaceFeatures } from '../useWorkspaceFeatures';

interface App {
	name: 'Administration' | 'CXM';
	description: string;
	icon: React.ElementType<SvgIconProps>;
	path: '/admin' | '/cxm';
}

const administrationApp: App = {
	name: 'Administration',
	description: 'Workspace administration & user management',
	icon: ManageAccountsOutlinedIcon,
	path: '/admin',
};

const cxmApp: App = {
	name: 'CXM',
	description: 'Order creation & opportunity tracking tools for sales associates',
	icon: ShoppingCartOutlinedIcon,
	path: '/cxm',
};

/** Get a list of apps based on the workspace's enabled features and the user's permissions. */
const useAllowedApps = (): { apps: App[]; loading: boolean; hasError: boolean } => {
	const { isFeatureEnabled, loading: loadingFeatures } = useWorkspaceFeatures();
	const isCXMEnabled = isFeatureEnabled('cxm');

	const isWorkspaceAdminResult = useIsWorkspaceAdmin();
	const isWorkspaceAdmin = isWorkspaceAdminResult === IsWorkspaceAdminResult.valid;
	const loadingIsWorkspaceAdmin = isWorkspaceAdminResult === IsWorkspaceAdminResult.pending;
	const hasError = isWorkspaceAdminResult === IsWorkspaceAdminResult.error;

	return {
		apps: [...(isWorkspaceAdmin ? [administrationApp] : []), ...(isCXMEnabled ? [cxmApp] : [])],
		loading: loadingFeatures || loadingIsWorkspaceAdmin,
		hasError,
	};
};

export default useAllowedApps;
