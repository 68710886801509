import { ApolloProvider } from '@apollo/client';
import type { AuthenticationResult, Configuration, EventMessage } from '@azure/msal-browser';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import {
	SingleColumnLayout,
	StorisThemeProvider,
	UnexpectedErrorView,
} from '@storis/app_common.components';
import { makeClient } from '@storis/app_common.graphql';
import type { CreateErrorNotificationParams } from '@storis/app_common.graphql';
import { create } from '@storis/app_common.redux';
import { RouterProvider } from '@storis/app_common.router';
import { isAcceptable, isStale } from '@storis/app_common.utils/jwt';
import {
	getStoredAccessToken,
	getStoredRefreshToken,
	setStoredAccessToken,
	setStoredRefreshToken,
} from '@storis/app_common.utils/storage';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader';
import { App } from '#features/App';
import getEnv from '#shared/utils/getEnv';
import makeRouter from './makeRouter';
import { makeStore } from './state';

const { graphqlApi, entraIdClientId, entraIdAuthority, entraIdRedirectUrl } = getEnv();

const msalConfig: Configuration = {
	auth: { clientId: entraIdClientId, authority: entraIdAuthority, redirectUri: entraIdRedirectUrl },
	cache: { cacheLocation: 'localStorage' },
	system: {
		allowPlatformBroker: false, // Disable WAM Broker
	},
};

const msalInstance = new PublicClientApplication(msalConfig);

const store = makeStore();

const ErrorBoundaryFallback = (props: { error: Error; resetErrorBoundary: () => void }) => (
	<SingleColumnLayout>
		<UnexpectedErrorView
			{...props}
			buttonText="Home"
			onClick={() => {
				window.location.assign('/');
			}}
		>
			An unexpected error has prevented this application from being presented
		</UnexpectedErrorView>
	</SingleColumnLayout>
);

const router = makeRouter({
	children: <App pca={msalInstance} />,
	ErrorBoundary: ErrorBoundaryFallback,
});

const unableToRefreshAccessToken = () => {
	setStoredRefreshToken(null);
	setStoredAccessToken(null);
	store.dispatch(create({ text: 'Unable to receive access from service' }));
};

const app = () => {
	const createErrorNotification = ({
		errorMessage,
		errorDetails,
	}: CreateErrorNotificationParams) => {
		store.dispatch(create({ text: errorMessage, moreInformation: errorDetails }));
	};

	const apolloClient = makeClient({
		graphqlApi,
		getRefreshToken: getStoredRefreshToken,
		createErrorNotification,
		getAccessToken: getStoredAccessToken,
		setAccessToken: setStoredAccessToken,
		setRefreshToken: setStoredRefreshToken,
		isAccessTokenAcceptable: (accessToken) => !isStale(accessToken, 0.9),
		isRefreshTokenAcceptable: isAcceptable,
		unableToRefreshAccessToken,
	});

	msalInstance
		.initialize()
		.then(() => {
			msalInstance.addEventCallback((event: EventMessage) => {
				if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
					const payload = event.payload as AuthenticationResult;
					const { account } = payload;
					msalInstance.setActiveAccount(account);
				}
				if (event.eventType === EventType.LOGOUT_SUCCESS) {
					msalInstance.setActiveAccount(null);
				}
			});

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const root = createRoot(document.querySelector('#main')!);
			root.render(
				<ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
					<StorisThemeProvider>
						<Provider store={store}>
							<ApolloProvider client={apolloClient}>
								<RouterProvider router={router} />
							</ApolloProvider>
						</Provider>
					</StorisThemeProvider>
				</ErrorBoundary>,
			);
		})
		.catch(() => {});
};

const webFontConfig = {
	google: { families: ['Roboto:300,400,500', 'Material Icons'] },
	classes: false,
	timeout: 5000,
	active: app,
	// If the font fails to load before the timeout, render the app anyway.
	// From my testing, inactive doesn't seem to get called immediately after the timeout.
	// For some reason it seems to wait for the network request to finish, then it waits the timeout length,
	// THEN it calls the inactive callback.
	inactive: app,
};

WebFont.load(webFontConfig);
