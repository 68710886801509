import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { create, dismiss, getNotifications } from './slice';
import type { Notification, NotificationsState } from './types';

export const useDismissNotification = () => {
	const dispatch = useDispatch();
	return useCallback(() => dispatch(dismiss()), [dispatch]);
};

export const useCreateNotification = () => {
	const dispatch = useDispatch();
	return useCallback((payload: Notification) => dispatch(create(payload)), [dispatch]);
};

export const useGetCurrentNotificationMessage = () => {
	const store = useStore<{ notifications: NotificationsState }>();
	return useCallback(() => {
		// this function allows the current notification to be checked for comparison purposes
		// we're intentionally using the selector function directly against the store rather than `useSelector`
		// this is because you can't `useSelector` conditionally (within a function) and using it outside the function
		// would trigger re-renders or cause hook updates due to dependency changes
		const notifications = getNotifications(store.getState());
		const notification = notifications.length > 0 ? notifications[0] : null;
		return notification?.text;
	}, [store]);
};
