import { Box, Button, FormLabel, Typography } from '@storis/app_common.ui/components';
import { useMemo, useState } from 'react';
import { BotDetection } from '#internal/components/BotDetection';
import { useBotDetection } from '#internal/hooks/useBotDetection';
import SignInCard from '../SignInCard';
import { useInitiatePasswordReset } from '../useInitiatePasswordReset';

interface CreatePasswordPromptCardProps {
	workspaceName: string;
	email: string;
	turnstileSiteKey: string;
	onSuccess: () => void;
}

const CreatePasswordPromptCard = (props: CreatePasswordPromptCardProps) => {
	const { workspaceName, email, turnstileSiteKey, onSuccess } = props;
	const [isSubmitted, setIsSubmitted] = useState(false);

	const {
		status: botDetectionStatus,
		errorCode,
		challengeResponse,
		isInteractive,
		handleSuccess: handleChallengeSuccess,
		handleError: handleChallengeError,
		handleExpire: handleChallengeExpire,
		handleBeforeInteractive,
	} = useBotDetection();

	const [initiatePasswordReset, { loading }] = useInitiatePasswordReset();

	const handleSubmit = () => {
		setIsSubmitted(true);
		if (challengeResponse != null) {
			initiatePasswordReset({ variables: { workspaceName, userEmail: email, challengeResponse } })
				.then(() => {
					onSuccess();
				})
				.catch(() => {});
		}
	};

	const errorMessage = useMemo(() => {
		if (botDetectionStatus === 'error') {
			return `Bot verification failed (Error: ${errorCode})`;
		}
		return null;
	}, [botDetectionStatus, errorCode]);

	return (
		<SignInCard title="Create New Password" loading={loading}>
			{errorMessage != null && <FormLabel error>{errorMessage}</FormLabel>}
			<Typography>
				Click the button below, and we&apos;ll email instructions to create a new password.
			</Typography>
			<Box>
				<Typography variant="subtitle2">Workspace</Typography>
				<Typography>{workspaceName}</Typography>
			</Box>
			<Box>
				<Typography variant="subtitle2">Email</Typography>
				<Typography>{email}</Typography>
			</Box>
			<BotDetection
				appearance="always"
				isInteractive={isInteractive}
				siteKey={turnstileSiteKey}
				isSubmitted={isSubmitted}
				botDetectionStatus={botDetectionStatus}
				onSuccess={handleChallengeSuccess}
				onError={handleChallengeError}
				onExpire={handleChallengeExpire}
				onBeforeInteractive={handleBeforeInteractive}
			/>
			<Button
				variant="contained"
				onClick={handleSubmit}
				disabled={botDetectionStatus === 'pending' || loading}
			>
				Send
			</Button>
		</SignInCard>
	);
};

export default CreatePasswordPromptCard;
