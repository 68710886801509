import { styled } from '@storis/app_common.ui/components';
import type { Breakpoint } from '@storis/app_common.ui/components';
import { ProgressContainer } from '../ProgressContainer';
import { PageContextProvider } from './context';

interface PageProps {
	Heading?: React.ReactNode;
	children: React.ReactNode;
	/**
	 * `isLoading` (default `false`)\
	 * If `true` an absolutely positioned linear progressbar will be presented below the `Heading` (or at the top, if
	 * there is no `Heading`)
	 */
	isLoading?: boolean;
	/**
	 * `rightPanelPinnedBreakpoint` (default `undefined`)\
	 * The breakpoint at which the right panel will be pinned to the right side of the page. Providing a value will cause
	 * the page to be rendered as a flex container that grows to fill the visible height, for viewports sized at or above
	 * the specified breakpoint
	 */
	rightPanelPinnedBreakpoint?: Breakpoint;
}

const PageRoot = styled('div')<Pick<PageProps, 'rightPanelPinnedBreakpoint'>>(
	({ theme, rightPanelPinnedBreakpoint }) =>
		rightPanelPinnedBreakpoint != null
			? {
					/*
					 * The page will present a pinned right panel at the specified breakpoint
					 * We need to ensure that the page content is rendered in a flex container that will grow to fill the space
					 */
					[theme.breakpoints.up(rightPanelPinnedBreakpoint)]: {
						display: 'flex',
						flexGrow: 1,
						minHeight: 0,
						maxWidth: 'unset',
						flexDirection: 'column',
						position: 'relative',
						height: '100vh',
					},
				}
			: {},
);

const Page = (props: PageProps) => {
	const { Heading, children, isLoading = false, rightPanelPinnedBreakpoint } = props;

	return (
		<PageRoot rightPanelPinnedBreakpoint={rightPanelPinnedBreakpoint}>
			{Heading ?? null}
			<ProgressContainer
				isLoading={isLoading}
				rightPanelPinnedBreakpoint={rightPanelPinnedBreakpoint}
			>
				<PageContextProvider rightPanelPinnedBreakpoint={rightPanelPinnedBreakpoint}>
					{children}
				</PageContextProvider>
			</ProgressContainer>
		</PageRoot>
	);
};

export default Page;
