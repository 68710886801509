import { useNavigate } from '@storis/app_common.router';
import { AppBar, Box, Button, Toolbar, Typography } from '@storis/app_common.ui/components';
import { LogoutIcon } from '@storis/app_common.ui/icons';
import { useCallback } from 'react';

interface AppToolbarProps {
	title?: string;
}

const AppToolbar = (props: AppToolbarProps) => {
	const { title } = props;

	const navigate = useNavigate();

	const onSignOutClick = useCallback(() => {
		navigate('/signout')?.catch(() => {});
	}, [navigate]);

	return (
		<AppBar position="static">
			<Toolbar>
				{title != null && (
					<Typography variant="h6" component="h1">
						{title}
					</Typography>
				)}
				<Box sx={{ ml: 'auto' }}>
					<Button color="inherit" startIcon={<LogoutIcon />} onClick={onSignOutClick}>
						Sign Out
					</Button>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default AppToolbar;
