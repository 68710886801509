import { gql } from '@apollo/client';

export const typeDefs = gql`
	"""
	Automatically fetch all pages and combine the returned data into one result.
	Does not return any data until all pages are finished loading.
	"""
	directive @fetchAll(
		"""
		The name of the page argument passed into the top-level field.
		"""
		pageArgument: String = "page"
		"""
		The maximum number of simultaneous network requests.
		"""
		maxConcurrentRequests: Int = 2
		"""
		The name of the array in the results. Defaults to the name of the top-level field.
		"""
		listName: String
	) on QUERY

	"""
	Automatically break the ids to chunks and fetch all data by chunks.
	Does not return any data until all chunks are finished loading.
	"""
	directive @fetchAllByIds(
		"""
		The name of id variable in operation that contains list of ids.
		"""
		idVariableName: String = "id"
		"""
		The maximum number of simultaneous network requests.
		"""
		maxConcurrentRequests: Int = 10
		"""
		The count of ids when fetching data in each chunk.
		"""
		chunkSize: Int = 30
	) on QUERY

	extend interface Item {
		images: [String!]
	}

	extend type Kit {
		images: [String!]
	}

	extend type Package {
		images: [String!]
	}

	extend type Product {
		images: [String!]
	}
`;
