import { gql } from '@apollo/client';

export const AuthenticateEntraIdUser = gql`
	mutation AuthenticateEntraIdUser($entraIdToken: String!, $workspaceName: String!) {
		userEntraIdAuthenticate(entraIdToken: $entraIdToken, workspaceName: $workspaceName) {
			__typename
			... on AuthenticationFailure {
				reason
			}
			... on AuthenticationSuccess {
				refreshToken
				accessToken
				idToken
			}
		}
	}
`;
