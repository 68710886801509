import { ApolloLink, Observable } from '@apollo/client';
import isOperationDefinitionNode from './isOperationDefinitionNode';

const timeoutLink = new ApolloLink((operation, forward) => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const operationType = operation.query.definitions.find(isOperationDefinitionNode)!.operation;

	if (operationType === 'subscription') {
		// timeout should not apply to subscriptions
		return forward(operation);
	}

	const { timeout = 30000 } = operation.getContext();

	return new Observable((subscriber) => {
		let timeoutId: number;

		const forwardSubscription = forward(operation).subscribe({
			next: (data) => {
				subscriber.next(data);
			},
			complete: () => {
				window.clearTimeout(timeoutId);
				subscriber.complete();
			},
			error: (err) => {
				window.clearTimeout(timeoutId);
				subscriber.error(err);
			},
		});

		timeoutId = window.setTimeout(() => {
			forwardSubscription.unsubscribe();
			subscriber.error(new Error('Request Timed Out'));
		}, timeout);

		return () => {
			window.clearTimeout(timeoutId);
			forwardSubscription.unsubscribe();
		};
	});
});

export default timeoutLink;
