import { useLocation, useNavigate } from '@storis/app_common.router';
import { useCallback } from 'react';

/** Returns a function that removes the hash from the URL */
const useRemoveUrlHash = () => {
	const navigate = useNavigate();
	const location = useLocation();

	return useCallback(() => {
		// remove hash from url
		navigate(location.pathname + location.search, { replace: true })?.catch(() => {});
	}, [location.pathname, location.search, navigate]);
};

export default useRemoveUrlHash;
