import { Notifications } from '@storis/app_common.components';
import { getNotifications, useDismissNotification } from '@storis/app_common.redux';
import { useSelector } from 'react-redux';

const HomeNotifications = () => {
	const notifications = useSelector(getNotifications);
	const onDismiss = useDismissNotification();

	return <Notifications notifications={notifications} onDismiss={onDismiss} />;
};

export default HomeNotifications;
