import type { Operation, UriFunction } from '@apollo/client';

// append the operation name with a parameter that is ignored by the service layer
// this allows us to block specific requests in dev tools: `*/graphql?op=GetPointOfSaleSettings`
// also allows for better logging & debugging in production
const getUri =
	(graphqlApi: string): string | UriFunction =>
	({ operationName, getContext }: Operation) => {
		const { logging } = getContext();

		const uri = `${graphqlApi}?op=${operationName}`;

		if (logging != null) {
			/*
			 * there is data on context that is intended for logging
			 * we'll encode it and append it to the uri so it can appear in the logs and be decoded
			 */
			const encodedLoggedData = encodeURIComponent(
				window.btoa(encodeURIComponent(JSON.stringify(logging))),
			);
			return `${uri}&l=${encodedLoggedData}`;
		}

		return uri;
	};

export default getUri;
