/**
 * `window.btoa` returns a base64 string, which can include a-zA-Z0-9+/=\
 * The symbols +/= should not be included as URI data because:
 *
 * - May be misconstrued as a space\
 * - / and = are reserved characters\
 *
 * Therefore, we encodeURIComponent the results.
 *
 * Javascript strings are usually 16 bit encoded string if we call `window.btoa` on it, it will cause a "Character Out
 * Of Range" exception if a character exceeds the range of a 8-bit ASCII-encoded character. Therefore we call
 * encodeURIComponent on the inner to handle this case.
 */
const encodeURIData = (data: unknown) =>
	encodeURIComponent(window.btoa(encodeURIComponent(JSON.stringify(data))));

export default encodeURIData;
