import type { FetchResult } from '@apollo/client';
import { ApolloLink, Observable } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import { set } from 'lodash';

// Check the response for errors and inject the requestId into the error objects.
// This link is separate from makeErrorLink because the fetchAll link can make multiple
// requests with multiple requestIds, but should only create one notification on error.
const requestIdLink = new ApolloLink(
	(operation, forward): Observable<FetchResult> =>
		new Observable((subscriber): (() => void) => {
			const forwardSubscription = forward(operation).subscribe({
				next: (data) => {
					if (data.errors) {
						const { response } = operation.getContext();
						const requestId = response.headers.get('x-request-id');
						data.errors.forEach((error) => {
							set(error, 'extensions.requestId', requestId);
						});
					}
					subscriber.next(data);
				},
				complete: () => {
					subscriber.complete();
				},
				error: (err) => {
					const { response } = operation.getContext();
					if (response != null) {
						const requestId = response.headers.get('x-request-id');
						if (err.result?.errors != null) {
							err.result.errors.forEach((error: GraphQLError) => {
								set(error, 'extensions.requestId', requestId);
							});
						} else if (err.result != null) {
							// eslint-disable-next-line no-param-reassign
							err.result.requestId = requestId;
						}
						// eslint-disable-next-line no-param-reassign
						err.requestId = requestId;
					}
					subscriber.error(err);
				},
			});
			return (): void => {
				forwardSubscription.unsubscribe();
			};
		}),
);

export default requestIdLink;
