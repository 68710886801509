import createGlobalState from './createGlobalState';
import { refreshTokenKey } from './keys';
import setStoredClockDriftFromToken from './setStoredClockDriftFromToken';
import setStoredItem from './setStoredItem';

const globalState = createGlobalState(refreshTokenKey, () => {});

const setStoredRefreshToken = (refreshToken: string | null): void => {
	setStoredClockDriftFromToken(refreshToken);
	setStoredItem(refreshTokenKey, refreshToken);
	// update all instances of useRefreshToken()
	globalState.emit(refreshToken);
};

export default setStoredRefreshToken;
