// key for the access token, stored in localStorage
export const accessTokenKey = 'access_token';

// key for the auth state, stored in localStorage
export const authStateKey = 'authState';

// key for the default workspace name used during login, stored in local storage
export const authWorkspaceNameKey = 'authWorkspaceName';

// key for the time of the user's last interaction with the site (for purposes of timing out for inactivity), stored in local storage
export const activityStorageKey = 'last-activity';

// key for user's identity info, stored in localStorage
export const identityKey = 'identity';

// key for the refresh token, stored in localStorage
export const refreshTokenKey = 'refresh_token';

/**
 * Key for the clock drift, stored in localStorage, used for checking token expiry.\
 * Consumers should not watch/subscribe to this value, because it is updated just before new tokens are stored.
 */
export const clockDriftKey = 'clock_drift';

// key for workspace information, stored in sessionStorage
export const workspaceKey = 'workspace';
