import { ApolloLink } from '@apollo/client';
import type { Operation } from '@apollo/client';
import { createOperation } from '@apollo/client/link/utils';
import { visit } from 'graphql';
import type { DocumentNode, FragmentDefinitionNode, FragmentSpreadNode } from 'graphql';

// Global in-memory cache of queries with minified fragment names.
// Will be wiped when closing/refreshing the page.
const cachedMinifiedQueries = new Map<string, DocumentNode>();

const getTransformedQuery = (operation: Operation): DocumentNode => {
	const cachedMinifiedQuery = cachedMinifiedQueries.get(operation.operationName);
	if (cachedMinifiedQuery != null && process.env.NODE_ENV === 'production') {
		// only use the cache in production mode, because it does not work with hot reloading.
		return cachedMinifiedQuery;
	}

	let nextFragmentNumber = 0;
	const fragmentNameToNumberMap = new Map<string, number>();

	const fragmentNodeTransformer = (node: FragmentDefinitionNode | FragmentSpreadNode) => {
		let fragmentNumber = fragmentNameToNumberMap.get(node.name.value);
		if (fragmentNumber == null) {
			fragmentNumber = nextFragmentNumber;
			fragmentNameToNumberMap.set(node.name.value, fragmentNumber);
			nextFragmentNumber += 1;
		}
		return { ...node, name: { ...node.name, value: `F${fragmentNumber}` } };
	};

	const transformedQuery = visit(operation.query, {
		enter: { FragmentDefinition: fragmentNodeTransformer, FragmentSpread: fragmentNodeTransformer },
	});

	cachedMinifiedQueries.set(operation.operationName, transformedQuery);
	return transformedQuery;
};

const fragmentNameMinifierLink = new ApolloLink((operation, forward) =>
	forward(
		createOperation(operation.getContext(), {
			...operation,
			query: getTransformedQuery(operation),
		}),
	),
);

export default fragmentNameMinifierLink;
