import { Box } from '@storis/app_common.ui/components';
import type { BoxProps } from '@storis/app_common.ui/components';

/**
 * `contentProps` will be spread onto the element containing `children`\
 * All other props will be spread onto the root element
 */
interface PageContentProps extends BoxProps {
	contentProps?: BoxProps;
}

const PageContent = (props: PageContentProps) => {
	const { children, contentProps, ...wrapperProps } = props;

	return (
		<Box {...wrapperProps}>
			<Box {...contentProps} sx={{ maxWidth: 1600, mx: 'auto', ...contentProps?.sx }}>
				{children}
			</Box>
		</Box>
	);
};

export default PageContent;
