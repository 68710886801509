import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import { Kind } from 'graphql';
import type { GraphQLError, OperationDefinitionNode } from 'graphql';

interface ErrorDetails {
	operationName: string;
	message: string;
	date: string;
	errors: readonly GraphQLError[] | Record<string, unknown>[] | ErrorResponse['networkError'][];
}

export interface CreateErrorNotificationParams {
	errorMessage: string;
	errorDetails: ErrorDetails;
}

export interface MakeErrorLinkParams {
	createErrorNotification: ({ errorMessage, errorDetails }: CreateErrorNotificationParams) => void;
}

const makeErrorLink = ({ createErrorNotification }: MakeErrorLinkParams) =>
	onError(({ networkError, graphQLErrors, operation }) => {
		const operationDefinition = operation.query.definitions.find(
			(definition): definition is OperationDefinitionNode =>
				definition.kind === Kind.OPERATION_DEFINITION,
		);
		const operationName = operationDefinition?.name?.value ?? '';

		const {
			errorMessage = `Operation ${operationName} failed`,
			suppressErrorNotification = false,
		} = operation.getContext();

		let errors;
		if (graphQLErrors) {
			errors = graphQLErrors;
		} else if (networkError && 'result' in networkError) {
			errors =
				typeof networkError.result === 'string'
					? [{ message: networkError.result }]
					: [networkError.result];
		} else {
			errors = [networkError];
		}

		const errorDetails = {
			operationName,
			message: errorMessage,
			date: new Date().toISOString(),
			errors,
		};
		if (!(suppressErrorNotification as boolean)) {
			createErrorNotification({ errorMessage, errorDetails });
		}

		return undefined;
	});

export default makeErrorLink;
