import type { HttpOptions, NormalizedCacheObject } from '@apollo/client';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import type { LDClient } from '@storis/app_common.feature_flags';
import { createUploadLink } from 'apollo-upload-client';
import { stripIgnoredCharacters } from 'graphql';
import { typeDefs } from '../typeDefs.gql';
import defaultOptions from './defaultOptions';
import delayLink from './delayLink';
import fetchAllByIdsLink from './fetchAllByIdsLink';
import fetchAllLink from './fetchAllLink';
import fragmentNameMinifierLink from './fragmentNameMinifierLink';
import getUri from './getUri';
import infiniteRetryLink from './infiniteRetryLink';
import makeAuthLink from './makeAuthLink';
import type { MakeAuthLinkParams } from './makeAuthLink';
import makeCacheOptions from './makeCacheOptions';
import makeErrorLink from './makeErrorLink';
import type { MakeErrorLinkParams } from './makeErrorLink';
import makeInvalidAccessTokenDetectorLink from './makeInvalidAccessTokenDetectorLink';
import makeTokenRefreshLink from './makeTokenRefreshLink';
import type { MakeTokenRefreshLinkParams } from './makeTokenRefreshLink';
import requestIdLink from './requestIdLink';
import timeoutLink from './timeoutLink';

interface MakeClientOptions {
	graphqlApi: string;
	requiresAuthLink?: boolean;
	requiresTokenRefreshLink?: boolean;
	createErrorNotification: MakeErrorLinkParams['createErrorNotification'];
	getAccessToken: MakeAuthLinkParams['getAccessToken'];
	setAccessToken?: MakeTokenRefreshLinkParams['setAccessToken'];
	getRefreshToken?: MakeTokenRefreshLinkParams['getRefreshToken'];
	setRefreshToken?: MakeTokenRefreshLinkParams['setRefreshToken'];
	isRefreshTokenAcceptable?: MakeTokenRefreshLinkParams['isRefreshTokenAcceptable'];
	isAccessTokenAcceptable?: MakeTokenRefreshLinkParams['isAccessTokenAcceptable'];
	unableToRefreshAccessToken?: MakeTokenRefreshLinkParams['unableToRefreshAccessToken'];
	ldClientRef?: { current: LDClient | null };
}

const customFetch: HttpOptions['fetch'] = (uri, options) => {
	if (typeof options?.body === 'string') {
		// We have to strip out whitespace characters with a custom fetch function
		// because apollo-upload-client does not support the `print` option from HttpLink
		const bodyObject = JSON.parse(options.body);
		bodyObject.query = stripIgnoredCharacters(bodyObject.query);
		// eslint-disable-next-line no-param-reassign
		options.body = JSON.stringify(bodyObject);
	}
	return fetch(uri, options);
};

const makeClient = ({
	graphqlApi,
	requiresAuthLink = true,
	requiresTokenRefreshLink = true,
	createErrorNotification,
	getAccessToken,
	getRefreshToken,
	setAccessToken,
	setRefreshToken,
	isRefreshTokenAcceptable,
	isAccessTokenAcceptable,
	unableToRefreshAccessToken,
	ldClientRef = { current: null },
}: MakeClientOptions): ApolloClient<NormalizedCacheObject> => {
	const authLink = makeAuthLink({ getAccessToken });
	const invalidAccessTokenDetectorLink = makeInvalidAccessTokenDetectorLink({ setAccessToken });
	const errorLink = makeErrorLink({ createErrorNotification });
	const tokenRefreshLink =
		setAccessToken != null &&
		getRefreshToken != null &&
		setRefreshToken != null &&
		isRefreshTokenAcceptable != null &&
		isAccessTokenAcceptable != null
			? makeTokenRefreshLink({
					graphqlApi,
					getRefreshToken,
					setRefreshToken,
					getAccessToken,
					setAccessToken,
					isRefreshTokenAcceptable,
					isAccessTokenAcceptable,
					unableToRefreshAccessToken,
				})
			: undefined;
	const terminatingLink = createUploadLink({ uri: getUri(graphqlApi), fetch: customFetch });

	// these links all deal with authentication and must go in this order:
	// -- authLink goes after tokenRefreshLink so that we use the new token after it gets refreshed
	// -- invalidAccessTokenDetectorLink goes before tokenRefreshLink so that when the access token gets cleared it will be refreshed
	const authLinks = [
		invalidAccessTokenDetectorLink, // clears stored access token if the server throws InvalidAccessTokenError
		...(requiresTokenRefreshLink && tokenRefreshLink != null
			? [
					tokenRefreshLink, // refreshes the access token if it has lived a long enough life
				]
			: []),
		...(requiresAuthLink
			? [
					authLink, // puts the current access token into the Authorization header
				]
			: []),
	];
	const cacheConfig = makeCacheOptions({ ldClientRef });

	return new ApolloClient({
		// -- makeErrorLink must go before the fetchAllLink
		// -- infiniteRetryLink must go after fetchAllLink so individual failed pages can be retried
		// -- timeoutLink must go after any links which perform multiple operations (e.g. infiniteRetryLink, fetchAllLink, authLinks)
		// -- authLinks should go after other links which perform multiple operations (e.g. infiniteRetryLink, fetchAllLink)
		// -- any links which deal with specific errors (e.g. invalidAccessTokenDetectorLink) should go after infiniteRetryLink
		// -- any links which need to access the response from the context object (e.g. requestIdLink) need to go after links which create a new operation (e.g. fragmentNameMinifierLink, fetchAllLink)
		// -- the terminating link (e.g. HttpLink, createUploadLink) must be the last link (https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link)
		link: ApolloLink.from([
			errorLink, // automatically creates a notification on error
			delayLink, // adds artificial network delay for debugging purposes.
			fetchAllByIdsLink, // implementation of the @fetchAllByIds directive for fetching all data by breaking ids to chunks
			fetchAllLink, // implementation of the @fetchAll directive for fetching all pages at once
			fragmentNameMinifierLink,
			infiniteRetryLink,
			...authLinks,
			timeoutLink,
			requestIdLink, // injects the requestId into error objects
			terminatingLink, // sending the composed operation to the GraphQL server
		]),
		typeDefs, // make local GraphQL types available in Apollo chrome extension
		cache: new InMemoryCache(cacheConfig), // cache for storing remote data. You can view the cache through the chrome extension
		defaultOptions,
	});
};

export default makeClient;
