import type { DefaultOptions, WatchQueryFetchPolicy } from '@apollo/client';

const defaultOptions: DefaultOptions = {
	watchQuery: {
		// specify our preferred default fetch policy
		fetchPolicy: 'cache-and-network',
		// nextFetchPolicy docs: https://www.apollographql.com/docs/react/data/queries/#nextfetchpolicy
		// istanbul ignore next - not worth the effort of testing this
		nextFetchPolicy(
			lastFetchPolicy: WatchQueryFetchPolicy,
			{ reason, initialFetchPolicy },
		): WatchQueryFetchPolicy {
			if (reason === 'variables-changed') {
				return initialFetchPolicy;
			}
			if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
				return 'cache-first';
			}
			return lastFetchPolicy;
		},
	},
};

export default defaultOptions;
