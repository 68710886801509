import { gql } from '@apollo/client';
import { useIsWorkspaceAdmin_MeFragment } from './fragments.gql';

export const GetMe = gql`
	query GetMe {
		me {
			id
			...useIsWorkspaceAdmin_MeFragment
		}
	}

	${useIsWorkspaceAdmin_MeFragment}
`;
