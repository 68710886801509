import type { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from 'react';

/** @knipignore - continue exporting the props type */
export interface AlertProps extends Omit<MuiAlertProps, 'components' | 'componentsProps'> {
	/**
	 * @deprecated `components` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slots` prop instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#components
	 */
	components?: never;
	/**
	 * @deprecated `componentsProps` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slotProps` prop instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#componentsprops
	 */
	componentsProps?: never;
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
	<MuiAlert {...props} ref={ref} />
));
