import type { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent';
import type { TypographyTypeMap as MuiTypographyTypeMap } from '@mui/material/Typography';
import MuiTypography from '@mui/material/Typography';

interface TypographyTypeMap<
	// eslint-disable-next-line @typescript-eslint/no-empty-object-type
	TAdditionalProps = {},
	TRootComponent extends React.ElementType = 'span',
> extends Omit<MuiTypographyTypeMap<TAdditionalProps, TRootComponent>, 'props'> {
	props: Omit<MuiTypographyTypeMap<TAdditionalProps, TRootComponent>['props'], 'paragraph'> & {
		/**
		 * @deprecated `paragraph` is deprecated in v6 and will be removed in v7.
		 *
		 *   It is recommended to specify `component="p"` a bottom margin of `16px` to achieve the same effect.
		 *
		 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#typography
		 */
		paragraph?: never;
	};
}

export type TypographyProps<
	TRootComponent extends React.ElementType = TypographyTypeMap['defaultComponent'],
	// eslint-disable-next-line @typescript-eslint/no-empty-object-type
	TAdditionalProps = {},
> = OverrideProps<TypographyTypeMap<TAdditionalProps, TRootComponent>, TRootComponent> & {
	component?: React.ElementType;
};

export const Typography: OverridableComponent<TypographyTypeMap> = MuiTypography;
