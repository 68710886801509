import createGlobalState from './createGlobalState';
import { accessTokenKey } from './keys';
import setStoredClockDriftFromToken from './setStoredClockDriftFromToken';
import setStoredItem from './setStoredItem';

const globalState = createGlobalState(accessTokenKey, () => {});

const setStoredAccessToken = (accessToken: string | null): void => {
	setStoredClockDriftFromToken(accessToken);
	setStoredItem(accessTokenKey, accessToken);
	// update all instances of useAccessToken()
	globalState.emit(accessToken);
};

export default setStoredAccessToken;
