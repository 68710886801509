import { Typography } from '@storis/app_common.ui/components';

interface MoreInfoAttributeProps {
	label: string;
	labelId: string;
	value: string;
}

const MoreInfoAttribute = (props: MoreInfoAttributeProps) => {
	const { label, labelId, value } = props;
	return (
		<>
			<Typography variant="subtitle1" color="inherit" id={labelId}>
				{label}
			</Typography>
			<Typography variant="subtitle1" color="textPrimary" gutterBottom aria-labelledby={labelId}>
				{value}
			</Typography>
		</>
	);
};

export default MoreInfoAttribute;
