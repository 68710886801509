import { gql } from '@apollo/client';

export const RefreshAccessToken = gql`
	mutation RefreshAccessToken($refreshToken: String!) {
		accessTokenRefresh(refreshToken: $refreshToken) {
			... on AccessTokenRefreshSuccess {
				refreshToken
				accessToken
			}

			... on AuthenticationFailure {
				reason
			}
		}
	}
`;
