import { useLazyQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { ControlledTextField } from '@storis/app_common.components';
import { useRemoveLocationParams } from '@storis/app_common.hooks';
import {
	Button,
	IconButton,
	InputAdornment,
	Stack,
	Tooltip,
} from '@storis/app_common.ui/components';
import { LockOpenIcon } from '@storis/app_common.ui/icons';
import {
	clearStorage,
	getStoredAuthWorkspaceName,
	setStoredAuthWorkspaceName,
	setStoredIdentity,
} from '@storis/app_common.utils/storage';
import { v } from '@storis/schema_validation';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type {
	AuthenticationFailureReason,
	GetUserAuthenticationMethodResult,
	GetUserAuthenticationMethodVariables,
} from '#internal/types/graphqlTypes';
import AuthenticationFailure from '../AuthenticationFailure';
import SignInCard from '../SignInCard';
import { GetUserAuthenticationMethod } from './mutations.gql';

const validationSchema = v.object({
	workspace: v
		.zodString()
		.trim()
		.min(1, { message: 'Required' })
		.transform((value) => value.toLocaleLowerCase()),
	email: v.zodString().min(1, { message: 'Required' }).email({ message: 'Invalid email address' }),
});

type FormValues = v.infer<typeof validationSchema>;

interface UseLockedFieldParams {
	initialValue: string;
}
const useLockedField = (params: UseLockedFieldParams) => {
	const { initialValue } = params;

	// The locked field will be locked if it has an initial value
	const [isLocked, setIsLocked] = useState(initialValue !== '');
	const inputRef = useRef<HTMLInputElement | null>(null);

	// Focus on the field when it is unlocked
	useEffect(() => {
		if (!isLocked) {
			inputRef.current?.focus();
		}
	}, [isLocked]);

	return useMemo(() => {
		return {
			inputRef,
			isLocked,
			onUnlock: () => {
				setIsLocked(false);
			},
		};
	}, [isLocked]);
};

interface UseWorkspaceAndEmailFormParams {
	defaultValues: FormValues;
}
const useWorkspaceAndEmailForm = (params: UseWorkspaceAndEmailFormParams) => {
	const { defaultValues } = params;

	return useForm<FormValues>({
		defaultValues,
		mode: 'onTouched',
		resolver: zodResolver(validationSchema),
	});
};

const useDefaultValues = () => {
	const removeLocationParams = useRemoveLocationParams();

	const [defaultValues] = useState<{ workspace: string; email: string }>(() => {
		/*
		 * Establish the initial value using the hierarchy
		 * Workspace name will be initialized using a hierarchy of sources:
		 * - query string parameter
		 * - local storage
		 */
		const { search } = window.location;
		const { workspace: workspaceQueryString } = qs.parse(search.substring(1)) as {
			workspace?: string;
		};
		const workspaceStored = getStoredAuthWorkspaceName();

		return { workspace: workspaceQueryString ?? workspaceStored ?? '', email: '' };
	});

	useEffect(() => {
		if (defaultValues != null) {
			// we're done with the workspace parameter
			removeLocationParams(['workspace']);
		}
	}, [defaultValues, removeLocationParams]);

	return defaultValues;
};

type UserAuthenticationMethod = GetUserAuthenticationMethodResult['userAuthenticationMethod'];

export interface OnWorkspaceAndEmailSuccessParams {
	userAuthenticationMethod: UserAuthenticationMethod;
	email: string;
}

interface WorkspaceAndEmailFormProps {
	failureReason?: AuthenticationFailureReason | null;
	onSuccess: ({ userAuthenticationMethod, email }: OnWorkspaceAndEmailSuccessParams) => void;
}

const WorkspaceAndEmailForm = (props: WorkspaceAndEmailFormProps) => {
	const { failureReason, onSuccess } = props;

	const [isLoading, setIsLoading] = useState(false);
	const defaultValues = useDefaultValues();
	const workspaceField = useLockedField({ initialValue: defaultValues.workspace });
	const { handleSubmit, control } = useWorkspaceAndEmailForm({ defaultValues });

	const [getUserAuthenticationMethod] = useLazyQuery<
		GetUserAuthenticationMethodResult,
		GetUserAuthenticationMethodVariables
	>(GetUserAuthenticationMethod, {
		context: { errorMessage: 'Unable to get user authentication method' },
	});

	const onSubmit = useCallback(() => {
		handleSubmit(({ workspace, email }) => {
			setIsLoading(true);
			getUserAuthenticationMethod({ variables: { workspaceName: workspace, email } })
				.then((response) => {
					// prior to login, make sure we're starting with a clean slate
					clearStorage();

					if (response.data?.userAuthenticationMethod != null) {
						onSuccess({ userAuthenticationMethod: response.data.userAuthenticationMethod, email });
					}
				})
				.catch(() => {})
				.finally(() => {
					setIsLoading(false);

					// persist submitted workspace and email
					setStoredAuthWorkspaceName(workspace);
					setStoredIdentity({ email });
				});
		})().catch(() => {});
	}, [getUserAuthenticationMethod, handleSubmit, onSuccess]);

	// Determine which field should be focused
	const autoFocusField = useMemo(
		() => (workspaceField.isLocked ? 'email' : 'workspace'),
		[workspaceField.isLocked],
	);

	return (
		<SignInCard title="Sign in to your account" loading={isLoading}>
			<AuthenticationFailure reason={failureReason} />
			<Stack
				gap={2}
				component="form"
				onSubmit={(e) => {
					onSubmit();
					e.preventDefault();
				}}
			>
				<ControlledTextField
					inputRef={workspaceField.inputRef}
					inputProps={{ form: 'none' }}
					required
					name="workspace"
					label="Workspace"
					fullWidth
					disabled={isLoading || workspaceField.isLocked}
					control={control}
					// InputProps is not a duplicate of inputProps
					// eslint-disable-next-line react/jsx-no-duplicate-props
					InputProps={{
						endAdornment: workspaceField.isLocked ? (
							<InputAdornment position="end">
								<Tooltip title="Change the workspace">
									<IconButton onClick={workspaceField.onUnlock} edge="end">
										<LockOpenIcon />
									</IconButton>
								</Tooltip>
							</InputAdornment>
						) : undefined,
					}}
					autoFocus={autoFocusField === 'workspace'}
				/>
				<ControlledTextField
					name="email"
					label="Email"
					type="email"
					autoComplete="username"
					required
					fullWidth
					disabled={isLoading}
					control={control}
					autoFocus={autoFocusField === 'email'}
				/>
				<Button variant="contained" type="submit" disabled={isLoading}>
					Continue
				</Button>
			</Stack>
		</SignInCard>
	);
};

export default WorkspaceAndEmailForm;
