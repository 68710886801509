import type {
	FieldFunctionOptions,
	FieldMergeFunction,
	FieldPolicy,
	InMemoryCacheConfig,
	TypePolicies,
} from '@apollo/client';
import type { LDClient } from '@storis/app_common.feature_flags';
import fragmentTypes from '../fragmentTypes';
import { nonPaginatedArrays, paginatedFields, unidentifiedTypeNames } from '../schemaData';
import { mockTypePolicies } from './mocks';

interface QueryResult {
	__typename: string;
	meta: { page: number };
	results: unknown[];
}

interface PaginatedQueryArgs {
	page: number;
	pageSize: number;
}

interface MakeCacheOptionsParams {
	ldClientRef?: { current: LDClient | null };
}

const makeCacheOptions = ({ ldClientRef = { current: null } }: MakeCacheOptionsParams) => {
	const mockedTypePolicies = mockTypePolicies(ldClientRef);
	const typePolicies: TypePolicies = {
		// Settings type does not have an id field.
		// This tells Apollo how to identify the Settings object without an id field.
		Settings: { keyFields: (): string => 'Settings' },
		// ItemSpecialOrderTemplate, ItemSpecialOrderTemplateOptionType, and ItemSpecialOrderOption types can be applied to multiple cart lines at the same time
		// except configured with different option prices. To keep the integrity of the option price, we will allow apollo cache these entities within their respective parent types
		// opposed to caching them as a standalone top level type.
		ItemSpecialOrderTemplateOptionType: { keyFields: false, merge: true },
		ItemSpecialOrderTemplate: { keyFields: false, merge: true },
		ItemSpecialOrderOption: { keyFields: false, merge: true },
		// Singleton types are configured with keyFields set to an empty array
		AdyenSettings: { keyFields: [] },
		FiservSettings: { keyFields: [] },
		FreedomPaySettings: { keyFields: [] },
		ElavonSettings: { keyFields: [] },
		SynchronySettings: { keyFields: [] },
		FairstoneFinancialSettings: { keyFields: [] },
		Item: { fields: { images: { read: (val = null) => val } } },
		Mutation: {
			fields: {
				relationshipAddAssignee: {
					merge: (existing, incoming, { cache }) => {
						// remove relationship list results from cache to force refetch
						cache.evict({ fieldName: 'relationships' });
						cache.gc();
						return incoming;
					},
				},
				relationshipRemoveAssignee: {
					merge: (existing, incoming, { cache }) => {
						// remove relationship list results from cache to force refetch
						cache.evict({ fieldName: 'relationships' });
						cache.gc();
						return incoming;
					},
				},
			},
		},
		...mockedTypePolicies,
	};

	// https://www.apollographql.com/docs/react/pagination/core-api/#designing-the-merge-function
	const paginatedQueryMerge: FieldMergeFunction<QueryResult> = (
		existing,
		incoming,
		{ args }: FieldFunctionOptions<PaginatedQueryArgs>,
	) => {
		if (existing == null || args?.page == null || args.page === 1) {
			return incoming;
		}

		if (args.page > existing.meta.page + 1) {
			// prevent merging in new results if new results are after the next expected page.
			// this can happen when spamming the sort buttons on a paginated list (the problem is easier to see if pageSize is <5).
			// it's ok if the new results are before the next expected page, there's only a problem when pages get skipped over.
			return existing;
		}

		// we sometimes refetch the latest page after deleting an item from the list,
		// so we want to merge the results together based on index rather than simply concatenating new results and existing results.
		const mergedArray = [...existing.results];
		const offset = (args.page - 1) * args.pageSize;
		incoming.results.forEach((val, index) => {
			mergedArray[offset + index] = val;
		});
		return {
			__typename: existing.__typename,
			meta: { ...existing.meta, ...incoming.meta },
			results: mergedArray,
		};
	};
	const paginatedKeyArgs: FieldPolicy['keyArgs'] = (args) => {
		const { page, pageSize, ...keyArgs } = args ?? {};
		return Object.keys(keyArgs);
	};

	// add in type policies for all paginated fields.
	Object.entries(paginatedFields).forEach(([typeName, fieldNames]) => {
		const typePolicy = typePolicies[typeName] ?? {};
		const fieldPolicies = typePolicy.fields ?? {};

		fieldNames.forEach((fieldName) => {
			const prevFieldPolicy = fieldPolicies[fieldName];
			const newFieldPolicy: FieldPolicy =
				// we currently don't use any field policies which are functions,
				// but the types allow it so maybe we will in the future.
				typeof prevFieldPolicy === 'function'
					? { read: prevFieldPolicy, keyArgs: paginatedKeyArgs, merge: paginatedQueryMerge }
					: { ...prevFieldPolicy, keyArgs: paginatedKeyArgs, merge: paginatedQueryMerge };
			fieldPolicies[fieldName] = newFieldPolicy;
		});

		typePolicy.fields = fieldPolicies;
		typePolicies[typeName] = typePolicy;
	});

	// add in type policies for non-paginated arrays.
	// When an element is removed from a non-paginated array, apollo client prints a warning to the console but otherwise behaves correctly.
	// specifying `merge: false` for non-paginated arrays will silence this warning while keeping the correct behavior.
	Object.entries(nonPaginatedArrays).forEach(([typeName, fieldNames]) => {
		const typePolicy = typePolicies[typeName] ?? {};
		const fieldPolicies = typePolicy.fields ?? {};

		fieldNames.forEach((fieldName) => {
			const prevFieldPolicy = fieldPolicies[fieldName];
			const newFieldPolicy: FieldPolicy =
				typeof prevFieldPolicy === 'function'
					? { read: prevFieldPolicy, merge: false }
					: { ...prevFieldPolicy, merge: false };
			fieldPolicies[fieldName] = newFieldPolicy;
		});

		typePolicy.fields = fieldPolicies;
		typePolicies[typeName] = typePolicy;
	});

	// add in type policies for unidentified object types.
	// Apollo client cannot safely merge objects together when they don't have a unique id field.
	// We designed our schema so that objects without an id are ALWAYS unique to the parent object,
	// so we can safely tell apollo client that these objects can be merged together.
	// Why apollo client doesn't merge unidentified objects by default: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
	unidentifiedTypeNames.forEach((typeName) => {
		typePolicies[typeName] = { ...typePolicies[typeName], merge: true };
	});

	const cacheOptions: InMemoryCacheConfig = {
		possibleTypes: fragmentTypes.possibleTypes,
		typePolicies,
	};

	return cacheOptions;
};

export default makeCacheOptions;
