import { useMutation } from '@apollo/client';
import { isAcceptable } from '@storis/app_common.utils/jwt';
import {
	getStoredAccessToken,
	getStoredRefreshToken,
	useAccessToken,
	useRefreshToken,
} from '@storis/app_common.utils/storage';
import { useState } from 'react';
import type {
	RefreshAccessTokenResult,
	RefreshAccessTokenVariables,
} from '#internal/types/graphqlTypes';
import { useGetMeDetails } from '../useGetMeDetails';
import { RefreshAccessToken } from './mutations.gql';

interface RefreshAccessTokenParams {
	canRefreshAccessToken: React.MutableRefObject<boolean>;
	onSuccessful?: () => void;
	onUnsuccessful: () => void;
}

const useRefreshAccessToken = (params: RefreshAccessTokenParams) => {
	const { canRefreshAccessToken, onSuccessful, onUnsuccessful } = params;
	const { getMeData } = useGetMeDetails();

	const [hasTokenError, setHasTokenError] = useState(false);
	const [, setAccessToken] = useAccessToken();
	const [, setRefreshToken] = useRefreshToken();

	const handleUnsuccessful = () => {
		setHasTokenError(true);
		onUnsuccessful();
	};

	const [refreshAccessToken] = useMutation<RefreshAccessTokenResult, RefreshAccessTokenVariables>(
		RefreshAccessToken,
		{ context: { skipAuth: true } },
	);

	const lockedRefreshAccessToken = async () => {
		await navigator.locks.request('refresh_access_token', async () => {
			// we have `refresh_access_token` locked

			// get the currently stored refresh token
			// bail if it is null, unacceptable, or malformed
			const currentRefreshToken = getStoredRefreshToken();
			if (currentRefreshToken == null || !isAcceptable(currentRefreshToken)) {
				return;
			}

			const currentAccessToken = getStoredAccessToken();
			if (currentAccessToken != null && isAcceptable(currentAccessToken)) {
				// access token has been refreshed by a previous request and is acceptable
				canRefreshAccessToken.current = true;
				return;
			}

			try {
				// use the refresh token to create a new access token
				// this request will invalidate the refresh token we provide
				const { data, errors } = await refreshAccessToken({
					variables: { refreshToken: currentRefreshToken },
				});

				const { accessTokenRefresh } = data ?? {};

				if (
					(errors ?? []).length > 0 ||
					accessTokenRefresh?.__typename === 'AuthenticationFailure'
				) {
					handleUnsuccessful();
					return;
				}

				if (accessTokenRefresh?.__typename === 'AccessTokenRefreshSuccess') {
					const { accessToken, refreshToken: newRefreshToken } = accessTokenRefresh;

					setAccessToken(accessToken);
					setRefreshToken(newRefreshToken);
					onSuccessful?.();

					// restore this so that we can create a new access token if necessary
					canRefreshAccessToken.current = true;
					getMeData().catch(() => {});
				}
			} catch {
				// failed to get new access token
				handleUnsuccessful();
			}
		});
	};

	return { refreshAccessToken: lockedRefreshAccessToken, hasTokenError };
};

export default useRefreshAccessToken;
