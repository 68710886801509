import createEmotionCache from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import {
	createTheme,
	CssBaseline,
	GlobalStyles,
	teal,
	ThemeProvider,
} from '@storis/app_common.ui/components';

const storis = createTheme({
	palette: {
		primary: { main: '#0A406A', contrastText: '#fff' },
		secondary: { main: '#EF5F37' },
		tertiary: { light: teal[300], main: teal.A700 },
	},
	components: {
		MuiAlert: {
			styleOverrides: {
				standard: ({ theme }) => {
					return {
						/*
						 * Ensure that the `standard` variant is rendered with a border matching the icon color
						 * The icon is currently styled using the `main` color variant of the corresponding severity
						 */
						'&.MuiAlert-standard': { borderWidth: 1, borderStyle: 'solid' },
						'&.MuiAlert-standardError': { borderColor: theme.palette.error.main },
						'&.MuiAlert-standardInfo': { borderColor: theme.palette.info.main },
						'&.MuiAlert-standardSuccess': { borderColor: theme.palette.success.main },
						'&.MuiAlert-standardWarning': { borderColor: theme.palette.warning.main },
					};
				},
			},
		},
		MuiAccordion: { defaultProps: { square: true } },
		MuiTextField: { defaultProps: { variant: 'outlined' } },
		MuiUseMediaQuery: { defaultProps: { noSsr: true } },
		appbarHeightMobile: 56,
		appbarHeightMobileLandscape: 48,
		appbarHeightDesktop: 64,
		bottomNavigationHeight: 56,
		tabsHeightIcon: 72,
		tabsHeightText: 48,
		navigationRailWidth: 72,
	},
	breakpoints: { values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 } },
});

// It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering.
// This will ensure that the <style> tag generated would not recalculate on each render.
// See https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override
const globalStyles = (
	<GlobalStyles
		styles={(theme) => {
			return {
				html: { boxSizing: 'border-box' },
				'*, *:before, *:after': { boxSizing: 'inherit' },
				body: {
					margin: 0,
					background: theme.palette.grey[100],
					fontFamily: theme.typography.fontFamily,
					color: theme.palette.text.primary,
					lineHeight: '1.2',
					overflowX: 'hidden',
					WebkitFontSmoothing: 'antialiased', // Antialiasing.
					MozOsxFontSmoothing: 'grayscale', // Antialiasing.
				},
				a: { color: theme.palette.secondary.main, textDecoration: 'none' },
				'a:hover': { textDecoration: 'underline' },
				img: { maxWidth: '100%', height: 'auto', width: 'auto' },
			};
		}}
	/>
);

// We need to provide a custom emotion cache to silence the console warnings about `:first-child` being unsafe.
// This will replace the emotion cache provided by MUI in StyledEngineProvider: https://github.com/mui/material-ui/blob/55331cf1e971f10e61eeff903a77ea4e3bbcab97/packages/mui-styled-engine/src/StyledEngineProvider/StyledEngineProvider.js
// See more about the emotion issue here: https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
const emotionCache = createEmotionCache({ key: 'css', prepend: true });
emotionCache.compat = true;

interface StorisThemeProviderProps {
	children: React.ReactNode;
}

const StorisThemeProvider = (props: StorisThemeProviderProps) => {
	const { children } = props;
	return (
		// The EmotionCacheProvider is only used to silence console warnings from emotion that say
		// "The pseudo class ':first-child' is potentially unsafe when doing server-side rendering".
		// We never use server-side rendering, so silencing the warning should be ok.
		// https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
		<ThemeProvider theme={storis}>
			<CssBaseline />
			{globalStyles}
			<EmotionCacheProvider value={emotionCache}>{children}</EmotionCacheProvider>
		</ThemeProvider>
	);
};

export default StorisThemeProvider;
