import { Content, PageContent } from '@storis/app_common.components';
import { useDocumentTitle } from '@storis/app_common.hooks';
import { useNavigate } from '@storis/app_common.router';
import { Box, Button, Typography } from '@storis/app_common.ui/components';
import { useCallback } from 'react';
import { AppToolbar } from '#shared/components';

const NotFoundPage = () => {
	const title = 'Not Found';

	useDocumentTitle(title);

	const navigate = useNavigate();

	const onHomeClick = useCallback(() => {
		navigate('/')?.catch(() => {});
	}, [navigate]);

	return (
		<>
			<AppToolbar title={title} />
			<PageContent>
				<Content>
					<Box sx={{ textAlign: 'center' }}>
						<Typography>
							Whatever you were looking for isn&apos;t where you thought it would be.
						</Typography>
						<Button variant="text" sx={{ my: 2 }} onClick={onHomeClick}>
							Home
						</Button>
					</Box>
				</Content>
			</PageContent>
		</>
	);
};

export default NotFoundPage;
