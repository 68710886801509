import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import type {
	GetMeWorkspaceFeaturesResult,
	GetMeWorkspaceFeaturesVariables,
	WorkspaceFeatureName,
} from '#internal/types/graphqlTypes';
import { GetMeWorkspaceFeatures } from './queries.gql';

const useWorkspaceFeatures = () => {
	const { data, loading } = useQuery<GetMeWorkspaceFeaturesResult, GetMeWorkspaceFeaturesVariables>(
		GetMeWorkspaceFeatures,
		{ fetchPolicy: 'cache-first', context: { infiniteRetry: true } },
	);

	const isFeatureEnabled = useCallback(
		(featureName: WorkspaceFeatureName) =>
			(data?.me?.workspace?.features ?? []).some(
				(feature) => feature.name === featureName && feature.isActive,
			),
		[data?.me?.workspace?.features],
	);

	return { isFeatureEnabled, loading };
};

export default useWorkspaceFeatures;
