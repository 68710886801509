import { useMutation } from '@apollo/client';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { SinglePageLayout } from '@storis/app_common.components';
import { useDocumentTitle } from '@storis/app_common.hooks';
import type { AppHash, AppRedirectHash } from '@storis/app_common.hooks';
import { clearStorage, setStoredAuthWorkspaceName } from '@storis/app_common.utils/storage';
import { decodeURIData, redirectWithHash } from '@storis/app_common.utils/uri';
import qs from 'qs';
import { useCallback, useEffect, useRef } from 'react';
import type { SignOutResult, SignOutVariables } from '#internal/types/graphqlTypes';
import { SignOut } from './mutations.gql';

interface UseSignOutParams {
	redirectPath: string;
}

const useSignOut = (params: UseSignOutParams) => {
	const { redirectPath } = params;

	const initiatedSignOut = useRef(false);
	const { instance } = useMsal();
	const isEntraIdAuthenticated = useIsAuthenticated();
	const [userSignOut] = useMutation<SignOutResult, SignOutVariables>(SignOut);

	/** Issue the user signout mutation, clear storage, and redirect */
	const applicationSignOut = useCallback(async () => {
		await userSignOut().catch(() => {});
		clearStorage();

		/*
		 * If the user is signing out because they're switching workspaces,
		 * we want to save the new workspace name in storage.
		 */
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('workspace')) {
			setStoredAuthWorkspaceName(searchParams.get('workspace'));
		}

		// Redirect options of `url` and `notification` may come from the hash, but we can default them otherwise
		const redirectOptions = { url: redirectPath, notification: 'You have been signed out.' };
		if (window.location.hash.includes('redirect')) {
			try {
				const { redirect = '' } = qs.parse(window.location.hash.substring(1)) as AppHash;
				const { notification, path } = JSON.parse(decodeURIData(redirect)) as AppRedirectHash;
				redirectOptions.url = path ?? redirectOptions.url;
				redirectOptions.notification = notification ?? redirectOptions.notification;
			} catch {
				// unable to parse redirect hash, we'll end up returning the default redirect options
			}
		}

		redirectWithHash(redirectOptions);
	}, [redirectPath, userSignOut]);

	// Initiate the sign out process if it hasn't already been initiated
	useEffect(() => {
		if (!initiatedSignOut.current) {
			initiatedSignOut.current = true;

			if (isEntraIdAuthenticated) {
				instance
					.logoutRedirect({
						account: instance.getActiveAccount(),
						onRedirectNavigate: () =>
							/*
							 * stop navigation after local logout
							 * see: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#skipping-the-server-sign-out
							 */
							false,
					})
					.finally(() => {
						applicationSignOut().catch(() => {});
					})
					.catch(() => {});
			} else {
				applicationSignOut().catch(() => {});
			}
		}
	}, [instance, isEntraIdAuthenticated, applicationSignOut]);
};

interface SignOutPageProps {
	redirectPath?: string;
}

const SignOutPage = (props: SignOutPageProps) => {
	const { redirectPath = '' } = props;
	useDocumentTitle(`Sign Out | STORIS`);
	useSignOut({ redirectPath });

	return <SinglePageLayout title="Signing out" isProcessing />;
};

export default SignOutPage;
