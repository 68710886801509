import { gql } from '@apollo/client';
import { useGetMeDetails_MeFragment } from './fragments.gql';

export const GetMeData = gql`
	query GetMeData {
		me {
			id
			...useGetMeDetails_MeFragment
		}
	}

	${useGetMeDetails_MeFragment}
`;
