import { useAllowedApps } from '@storis/app_common-graphql.hooks/useAllowedApps';
import { Page, SingleColumnLayout } from '@storis/app_common.components';
import { useDocumentTitle } from '@storis/app_common.hooks';
import { Stack, Typography } from '@storis/app_common.ui/components';
import { AppToolbar } from '#shared/components';
import AppCard from './AppCard';

const NextGenHome = () => {
	useDocumentTitle('NextGen Home');
	const { apps, loading, hasError } = useAllowedApps();

	// If the user only has access to one app, redirect to that app.
	if (!loading && !hasError && apps.length === 1) {
		window.location.assign(apps[0].path);
	}

	return (
		<Page Heading={<AppToolbar title="NextGen Home" />} isLoading={loading}>
			{!loading && (
				<SingleColumnLayout>
					{apps.length > 1 && (
						<>
							<Typography variant="h4" component="h2" color="primary" sx={{ mb: 3 }}>
								Apps
							</Typography>
							<Stack spacing={2}>
								{apps.map((app) => (
									<AppCard
										key={app.path}
										title={app.name}
										description={app.description}
										path={app.path}
										icon={app.icon}
									/>
								))}
							</Stack>
						</>
					)}

					{apps.length === 0 && (
						<>
							<Typography align="center" component="p" variant="h6" color="textSecondary">
								You do not have access to any apps from this workspace
							</Typography>
							<Typography align="center" component="p" variant="h6" color="textSecondary">
								Please contact your administrator for access
							</Typography>
						</>
					)}
				</SingleColumnLayout>
			)}
		</Page>
	);
};

export default NextGenHome;
