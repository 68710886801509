import createPersistedState from './createPersistedState';
import { identityKey } from './keys';
import type { Identity } from '.';

const useIdentityState = createPersistedState<Identity | null>(identityKey);

/**
 * Hook that returns the currently stored user's identity and a function to update it\
 * This is the preferred method for accessing and setting the user's identity in React components and hooks
 */
const useIdentity = useIdentityState;

export default useIdentity;
