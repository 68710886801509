import { Button } from '@storis/app_common.ui/components';
import type { ButtonProps } from '@storis/app_common.ui/components';
import { FileCopyIcon } from '@storis/app_common.ui/icons';
import type { SvgIconProps } from '@storis/app_common.ui/icons';

// all other props will be spread to the base Button component
interface MoreInfoCopyButtonProps extends ButtonProps {
	IconProps: SvgIconProps;
}

const MoreInfoCopyButton = (props: MoreInfoCopyButtonProps) => {
	const { IconProps, ...rest } = props;
	return (
		<Button color="primary" {...rest}>
			<FileCopyIcon {...IconProps} />
			Copy
		</Button>
	);
};

export default MoreInfoCopyButton;
