import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { ControlledTextField } from '@storis/app_common.components';
import { Box, Button, Stack, Typography } from '@storis/app_common.ui/components';
import { decodeToken } from '@storis/app_common.utils/jwt';
import {
	setStoredAccessToken,
	setStoredIdentity,
	setStoredRefreshToken,
} from '@storis/app_common.utils/storage';
import { v } from '@storis/schema_validation';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import type { IDTokenJwt } from '#internal/types';
import type {
	AuthenticateUserResult,
	AuthenticateUserVariables,
} from '#internal/types/graphqlTypes';
import AuthenticationFailure from '../AuthenticationFailure';
import SignInCard from '../SignInCard';
import { AuthenticateUser } from './mutations.gql';

const validationSchema = v.object({ password: v.zodString().min(1, { message: 'Required' }) });

type FieldValues = v.infer<typeof validationSchema>;

interface EnterPasswordCardProps {
	workspaceName: string;
	email: string;
	onForgotPassword: () => void;
}

const EnterPasswordCard = (props: EnterPasswordCardProps) => {
	const { workspaceName, email, onForgotPassword } = props;

	const { control, handleSubmit } = useForm<FieldValues>({
		defaultValues: { password: '' },
		// we are intentionally validating this form on submit
		// it is unnecessary to present validation errors on the password field prior to submitting the form
		// if the user submits the form without entering a password, presenting field errors make sense
		// it is also worth noting that the user may not intend on submitting a password because they need to click `forgot password`
		mode: 'onSubmit',
		resolver: zodResolver(validationSchema),
	});

	const [authenticate, { data, loading }] = useMutation<
		AuthenticateUserResult,
		AuthenticateUserVariables
	>(AuthenticateUser, { context: { errorMessage: 'Unable to authenticate' } });

	const authenticationFailureError =
		data?.userPasswordAuthenticate.__typename === 'AuthenticationFailure'
			? data.userPasswordAuthenticate.reason
			: null;

	const onSubmit = useCallback(() => {
		handleSubmit(({ password }) => {
			authenticate({ variables: { workspaceName, email, password } })
				.then((response) => {
					if (response.data?.userPasswordAuthenticate.__typename === 'AuthenticationSuccess') {
						const identity = decodeToken<IDTokenJwt>(
							response.data.userPasswordAuthenticate.idToken,
						);
						setStoredIdentity({
							name: `${identity?.given_name} ${identity?.family_name}`,
							email: identity?.email,
							staffId: identity?.staffId,
							userId: identity?.sub,
						});
						setStoredRefreshToken(response.data.userPasswordAuthenticate.refreshToken);
						setStoredAccessToken(response.data.userPasswordAuthenticate.accessToken);
					}
				})
				.catch(() => {});
		})().catch(() => {});
	}, [authenticate, email, handleSubmit, workspaceName]);

	return (
		<SignInCard title="Sign in to your account" loading={loading}>
			<AuthenticationFailure reason={authenticationFailureError} />
			<Box>
				<Typography variant="subtitle2">Workspace</Typography>
				<Typography>{workspaceName}</Typography>
			</Box>
			<Box>
				<Typography variant="subtitle2">Email</Typography>
				<Typography>{email}</Typography>
			</Box>
			<Stack
				gap={2}
				component="form"
				onSubmit={(e) => {
					onSubmit();
					e.preventDefault();
				}}
			>
				<ControlledTextField
					label="Password"
					name="password"
					aria-label="Password"
					endAdornmentVariant="restricted"
					autoComplete="current-password"
					disabled={loading}
					control={control}
					autoFocus
				/>
				<Box>
					<Button onClick={onForgotPassword}>Forgot Password?</Button>
				</Box>
				<Button variant="contained" type="submit" disabled={loading}>
					Sign In
				</Button>
			</Stack>
		</SignInCard>
	);
};

export default EnterPasswordCard;
