/**
 * Store an item in storage (localStorage by default).\
 * Undefined will remove the item from storage.
 */
const setStoredItem = (
	key: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any,
	storage: Storage = localStorage,
): void => {
	if (value === undefined) {
		storage.removeItem(key);
		return;
	}
	try {
		storage.setItem(key, JSON.stringify(value));
	} catch {
		// unable to stringify, store provided value
		storage.setItem(key, value);
	}
};

export default setStoredItem;
