import makeURIHash from './makeURIHash';

const redirectWithHash = (options: {
	url: string;
	includeLogout?: boolean;
	includeLocation?: boolean;
	notification?: string;
}): void => {
	const { url, includeLogout = true, includeLocation = true, notification } = options;

	// construct the redirect hash
	const hash = makeURIHash({
		logout: includeLogout ? true : undefined,
		url: includeLocation ? window.location.href : undefined,
		notification,
	});

	// if the hash is not null, append it to the URL
	const redirectUrl = hash != null ? `${url}#redirect=${hash}` : url;

	window.location.assign(redirectUrl);
};

export default redirectWithHash;
