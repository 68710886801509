import { useCallback } from 'react';
import createPersistedState from './createPersistedState';
import { refreshTokenKey } from './keys';
import setStoredClockDriftFromToken from './setStoredClockDriftFromToken';
import setStoredItem from './setStoredItem';

const useRefreshTokenState = createPersistedState<string | null>(refreshTokenKey);
/**
 * Hook that returns the currently stored refresh token and a function to update it\
 * This is the preferred method for refreshing and setting the refresh token in React components and hooks
 */
const useRefreshToken: typeof useRefreshTokenState = () => {
	// createPersistedState stores values as JSON, so stored values must be parsed
	// make sure that the stored refresh token can be parsed, if not, store null
	const storedRefreshToken = localStorage.getItem(refreshTokenKey);
	if (storedRefreshToken != null) {
		try {
			JSON.parse(storedRefreshToken);
		} catch {
			setStoredItem(refreshTokenKey, null);
		}
	}

	const [refreshToken, setPersistedState] = useRefreshTokenState();

	const customSetState: typeof setPersistedState = useCallback(
		(newValue) => {
			setStoredClockDriftFromToken(newValue);
			setPersistedState(newValue);
		},
		[setPersistedState],
	);

	return [refreshToken, customSetState];
};

export default useRefreshToken;
