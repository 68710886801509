import createGlobalState from './createGlobalState';
import { identityKey } from './keys';
import setStoredItem from './setStoredItem';
import type { Identity } from '.';

const globalState = createGlobalState(identityKey, () => {});

const setStoredIdentity = (identity: Identity | null): void => {
	setStoredItem(identityKey, identity);
	// update all instances of useIdentityState()
	globalState.emit(identity);
};

export default setStoredIdentity;
