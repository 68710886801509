import { Button } from '@storis/app_common.ui/components';
import type { NotificationInformation } from './types';

interface ActionButtonProps {
	moreInformation?: NotificationInformation;
	onActionClick?: () => void;
	onMoreInfoClick: () => void;
	snackbarAction?: string;
}

const ActionButton = (props: ActionButtonProps) => {
	const { moreInformation, onActionClick, onMoreInfoClick, snackbarAction } = props;

	const hasSnackbarAction = snackbarAction != null && onActionClick != null;
	const hasButton = moreInformation != null || hasSnackbarAction;
	const buttonText = hasSnackbarAction ? snackbarAction : 'More Info';
	const onButtonClick = hasSnackbarAction ? onActionClick : onMoreInfoClick;

	return hasButton ? (
		<Button
			data-testid="Notifications-button"
			color="secondary"
			size="small"
			onClick={onButtonClick}
		>
			{buttonText}
		</Button>
	) : null;
};

export default ActionButton;
