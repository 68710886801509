import getStoredItem from './getStoredItem';
import { clockDriftKey } from './keys';

/**
 * A number representing how many seconds ahead of server time the device clock is. A negative number means the device
 * clock is behind server time.
 */
const getStoredClockDrift = () => getStoredItem<number | null>(clockDriftKey);

export default getStoredClockDrift;
