import { Box, Container } from '@storis/app_common.ui/components';
import { ContentLayout } from '../ContentLayout';
import type { ContentLayoutProps } from '../ContentLayout';

interface SingleColumnLayoutProps extends ContentLayoutProps {
	children: React.ReactNode;
}

const SingleColumnLayout = (props: SingleColumnLayoutProps) => {
	const { FAB, children, isLoading = false } = props;

	return (
		<ContentLayout isLoading={isLoading} FAB={FAB}>
			<Container maxWidth="sm">
				<Box
					sx={{
						/*
						 * Vertical padding matches spacing defined by `Container` for gutters
						 * When a FAB is presented, we don't specify bottom padding, allowing `ContentLayout`
						 * to specify its own bottom padding so the FAB doesn't  overlap the content when
						 * scrolled to the bottom of the page
						 */
						pt: { xs: 2, sm: 3 },
						pb: FAB != null ? undefined : { xs: 2, sm: 3 },
					}}
				>
					{children}
				</Box>
			</Container>
		</ContentLayout>
	);
};

export default SingleColumnLayout;
