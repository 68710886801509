import { gql } from '@apollo/client';

export const AuthenticateUser = gql`
	mutation AuthenticateUser($email: String!, $password: String!, $workspaceName: String!) {
		userPasswordAuthenticate(email: $email, password: $password, workspaceName: $workspaceName) {
			... on AuthenticationSuccess {
				refreshToken
				accessToken
				idToken
			}
			... on AuthenticationFailure {
				reason
			}
		}
	}
`;
