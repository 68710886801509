import { useGetMeDetails } from '@storis/app_common-graphql.hooks/useGetMeDetails';
import { useInactivityTimeout } from '@storis/app_common-graphql.hooks/useInactivityTimeout';
import { Content } from '@storis/app_common.components';
import type { Notification } from '@storis/app_common.components';
import { useRemoveLocationParams } from '@storis/app_common.hooks';
import { useLocation, useNavigate } from '@storis/app_common.router';
import { LinearProgress, Typography } from '@storis/app_common.ui/components';
import { initializePendo } from '@storis/app_common.utils/misc';
import {
	getStoredAuthWorkspaceName,
	getStoredIdentity,
	useIdentity,
} from '@storis/app_common.utils/storage';
import qs from 'qs';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { Auth_ApplicationTimeoutSettingsFragment } from '#internal/types/graphqlTypes';
import { CreatePassword } from './CreatePassword';
import { PasswordResetCancel } from './PasswordResetCancel';
import SignIn from './SignIn';
import type { AuthState } from './useAuthState';
import { useAuthState } from './useAuthState';

interface UseWorkspaceChangeParams {
	state: AuthState;
	logoutPath: string;
}

const useWorkspaceChange = (params: UseWorkspaceChangeParams) => {
	const { state, logoutPath } = params;
	const isRedirecting = useRef(false);
	const navigate = useNavigate();
	const location = useLocation();
	const removeLocationParams = useRemoveLocationParams();

	/**
	 * If the user is authorized, remove all location params\
	 * We don't want things like `workspace` or `redirected` to stick around in the URL (Especially if they're logged into
	 * a different URL)
	 */
	useEffect(() => {
		if (!isRedirecting.current && state === 'authenticated') {
			isRedirecting.current = true;
			const { workspace: workspaceQueryString } = qs.parse(location.search.substring(1)) as {
				workspace?: string;
			};
			const workspaceStored = getStoredAuthWorkspaceName();

			if (
				workspaceQueryString != null &&
				workspaceQueryString !== workspaceStored &&
				location.pathname !== logoutPath
			) {
				navigate(`${logoutPath}?workspace=${workspaceQueryString}`)?.catch(() => {});
			} else if (location.pathname !== logoutPath) {
				removeLocationParams();
			}
		}
	}, [navigate, location.pathname, location.search, logoutPath, removeLocationParams, state]);
};

interface UseRedirectLogoutParams {
	state: AuthState;
	logoutPath: string;
}

const useRedirectLogout = ({ state, logoutPath }: UseRedirectLogoutParams) => {
	const isRedirecting = useRef(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (!isRedirecting.current && state === 'unauthenticated' && location.pathname === logoutPath) {
			isRedirecting.current = true;
			navigate('/', { replace: true })?.catch(() => {});
		}
	}, [navigate, location.pathname, logoutPath, state]);
};

const useAuthQueryString = () => {
	// derive state from the querystring
	const navigate = useNavigate();
	const location = useLocation();
	const authQueryString = qs.parse(location.search.substring(1)) as {
		action?: string;
		workspaceId?: string;
		requestId?: string;
		token?: string;
		exp?: string;
	};
	const [action, setAction] = useState(authQueryString.action);
	const [workspaceId, setWorkspaceId] = useState(authQueryString.workspaceId);
	const [requestId, setRequestId] = useState(authQueryString.requestId);
	const [token, setToken] = useState(authQueryString.token);
	const [exp, setExp] = useState(authQueryString.exp);

	// consume the querystring and rely on the derived state
	useEffect(() => {
		if (
			action != null ||
			workspaceId != null ||
			requestId != null ||
			token != null ||
			exp != null
		) {
			const newUrl = new URL(window.location.href);
			newUrl.searchParams.delete('action');
			newUrl.searchParams.delete('workspaceId');
			newUrl.searchParams.delete('requestId');
			newUrl.searchParams.delete('token');
			newUrl.searchParams.delete('exp');
			navigate({ search: newUrl.search }, { replace: true })?.catch(() => {});
		}
	}, [action, exp, navigate, requestId, token, workspaceId]);

	return useMemo(() => {
		return {
			action,
			workspaceId,
			requestId,
			token,
			exp,
			// since we're consuming the query string, we need a way of resetting the state that was derived from it
			reset: () => {
				setAction(undefined);
				setWorkspaceId(undefined);
				setRequestId(undefined);
				setToken(undefined);
				setExp(undefined);
			},
		};
	}, [action, exp, requestId, token, workspaceId]);
};

interface UseAuthQueryStringParams {
	state: AuthState;
}

const useAuthenticationInitialization = (params: UseAuthQueryStringParams) => {
	const { state } = params;

	const { getMeData, loading, called } = useGetMeDetails();
	const isGettingMeData = useRef(false);

	useEffect(() => {
		if (state === 'authenticated' && !isGettingMeData.current) {
			isGettingMeData.current = true;
			getMeData().catch(() => {});
		}
	}, [getMeData, state]);

	return useMemo(() => {
		return {
			/*
			 * we're initializing until the me query has been called and is no longer loading
			 * we may be authenticated, but we don't want to render the app until this query resolves
			 */
			initializing: called ? loading : true,
		};
	}, [called, loading]);
};

interface AuthProps {
	appPath: string;
	turnstileSiteKey: string;
	children: React.ReactNode;
	createNotification: (payload: Notification) => void;
	dismissNotification: () => void;
	getCurrentNotificationMessage: () => string | undefined;
	timeoutSettings?: Auth_ApplicationTimeoutSettingsFragment | null;
	pendoSettings?: { environment: string; apiKey: string };
}

const Auth = (props: AuthProps) => {
	const {
		appPath,
		children,
		turnstileSiteKey,
		createNotification,
		dismissNotification,
		getCurrentNotificationMessage,
		timeoutSettings,
		pendoSettings,
	} = props;

	const [identity] = useIdentity();
	const { accessToken, state, timeout, entraId } = useAuthState({ appPath });
	const [isPendoInitialized, setIsPendoInitialized] = useState(false);

	// Once authenticated, issue a me query to get information about the workspace
	const { initializing } = useAuthenticationInitialization({ state });

	useRedirectLogout({ state, logoutPath: '/signout' });
	useWorkspaceChange({ state, logoutPath: '/signout' });

	useInactivityTimeout({
		inactivityPeriod: timeoutSettings?.inactivityPeriod,
		warningPeriod: timeoutSettings?.warningPeriod,
		// user signed out due to inactivity
		logout: timeout,
		createNotification,
		dismissNotification,
		getCurrentNotificationMessage,
		isAuthenticated: state === 'authenticated' && identity != null,
	});

	const { action, workspaceId, requestId, token, exp, reset } = useAuthQueryString();

	if (
		action === 'password-reset' &&
		workspaceId != null &&
		requestId != null &&
		token != null &&
		exp != null
	) {
		return (
			<CreatePassword
				turnstileSiteKey={turnstileSiteKey}
				requestId={requestId}
				token={token}
				workspaceId={workspaceId}
				exp={exp}
				onSignIn={reset}
			/>
		);
	}

	if (
		action === 'password-reset-cancel' &&
		workspaceId != null &&
		requestId != null &&
		token != null
	) {
		return (
			<PasswordResetCancel
				turnstileSiteKey={turnstileSiteKey}
				requestId={requestId}
				token={token}
				workspaceId={workspaceId}
			/>
		);
	}

	if (state === 'unauthenticated') {
		return (
			<SignIn
				turnstileSiteKey={turnstileSiteKey}
				createNotification={createNotification}
				entraId={entraId}
			/>
		);
	}

	// We need the identity to be set before initializing Pendo
	if (state === 'authenticated' && identity != null && !initializing) {
		if (
			pendoSettings != null &&
			!isPendoInitialized &&
			accessToken != null &&
			pendoSettings.apiKey != null
		) {
			const { apiKey, environment } = pendoSettings;

			const { userId = '', email = '' } = getStoredIdentity() ?? {};
			const workspaceName = getStoredAuthWorkspaceName() ?? null;

			initializePendo(apiKey, { id: userId, email }, { id: workspaceName, environment });
			setIsPendoInitialized(true);
		}

		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{children}</>;
	}

	if (state === 'loading' || initializing) {
		return (
			<Content>
				<Typography
					data-testid="Auth-authorizing"
					variant="h5"
					component="p"
					sx={{ marginBottom: '16px' }}
				>
					Authorizing
				</Typography>
				<LinearProgress />
			</Content>
		);
	}

	return null;
};

export default Auth;
