/**
 * Retrieve an item from storage (localStorage by default).\
 * Undefined indicates the key does not exist in storage.
 *
 * @todo [2025-02-28]: CXM-9276 Add zod schema validation logic instead of generic
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const getStoredItem = <TStoredItem>(
	key: string,
	storage: Storage = localStorage,
): TStoredItem | undefined => {
	const storedValue = storage.getItem(key);
	try {
		return storedValue != null ? JSON.parse(storedValue) : undefined;
	} catch {
		// unable to parse, return unparsed stored value
		return storedValue as unknown as TStoredItem;
	}
};

export default getStoredItem;
