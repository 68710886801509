import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
import type { State } from '.';

type PreloadedState = Parameters<typeof configureStore<Partial<State>>>[0]['preloadedState'];

const makeStore = (preloadedState?: PreloadedState) =>
	configureStore({
		reducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				thunk: true,
				immutableCheck: { warnAfter: 100 },
				serializableCheck: false,
			}),
		preloadedState,
	});

export default makeStore;
