import type { Breakpoint } from '@storis/app_common.ui/components';
import { createContext, useContext, useMemo } from 'react';

interface PageContextValue {
	rightPanelPinnedBreakpoint?: Breakpoint;
}

const PageContext = createContext<PageContextValue>({});

interface PageContextProviderProps {
	children: React.ReactNode;
	rightPanelPinnedBreakpoint?: Breakpoint;
}

/**
 * This is for internal use only and is not exported from `@storis/app_common.components`\
 * It wraps the children rendered by the `Page` component
 */
export const PageContextProvider = (props: PageContextProviderProps) => {
	const { children, rightPanelPinnedBreakpoint } = props;

	const contextValue = useMemo(() => {
		return { rightPanelPinnedBreakpoint };
	}, [rightPanelPinnedBreakpoint]);

	return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
};

/**
 * This is for internal use only and is not exported from `@storis/app_common.components`\
 * It is available for components in this package that are meant to be used within a `Page` component
 */
export const usePageContext = () => {
	const contextValue = useContext(PageContext);

	if (contextValue === undefined) {
		throw new Error('usePageContext must be used within PageContextProvider');
	}

	return contextValue;
};
