import { Turnstile } from '@marsidev/react-turnstile';
import { Box, FormLabel } from '@storis/app_common.ui/components';
import { useMemo } from 'react';

interface BotDetectionProps {
	appearance?: 'always' | 'interaction-only';
	isInteractive: boolean;
	siteKey: string;
	isSubmitted: boolean;
	botDetectionStatus: 'pending' | 'error' | 'solved';
	onSuccess: (token: string) => void;
	onError: (errorCode: string) => void;
	onExpire: () => void;
	onBeforeInteractive: () => void;
}

const BotDetection = (props: BotDetectionProps) => {
	const {
		appearance = 'interaction-only',
		isInteractive,
		siteKey,
		isSubmitted,
		botDetectionStatus,
		onSuccess,
		onError,
		onExpire,
		onBeforeInteractive,
	} = props;

	const display = useMemo(() => {
		if (appearance === 'always') {
			return 'block';
		}

		return isInteractive ? 'block' : 'none';
	}, [appearance, isInteractive]);

	return (
		<Box sx={{ display }}>
			<Turnstile
				siteKey={siteKey}
				onSuccess={onSuccess}
				onError={onError}
				onExpire={onExpire}
				options={{ appearance, theme: 'light', size: 'normal' }}
				onBeforeInteractive={onBeforeInteractive}
			/>
			{isSubmitted && botDetectionStatus === 'pending' && (
				<FormLabel component="div" error sx={{ mt: 1 }}>
					Please click the checkbox for verification
				</FormLabel>
			)}
		</Box>
	);
};

export default BotDetection;
