import { Snackbar, styled } from '@storis/app_common.ui/components';
import { useMemo, useState } from 'react';
import ActionButton from './ActionButton';
import MoreInfoDialog from './MoreInfoDialog';
import type { Notification } from './types';

interface UseSnackbarStateParams {
	message?: string | null;
	onDismiss?: () => void;
}

const useSnackbarState = (params: UseSnackbarStateParams) => {
	const { message, onDismiss } = params;
	const [state, setState] = useState<'entering' | 'entered' | 'exiting' | 'exited'>('exited');

	if (state === 'exited' && message != null) {
		// the snackbar is not presented and there is a notification
		setState('entering');
	} else if (state === 'entered' && message == null) {
		// the snackbar is presented and there is no notification
		setState('exiting');
	}

	return useMemo(() => {
		return {
			open: state === 'entering' || state === 'entered',
			/*
			 * Consumer invoked this function to indicate that the snackbar should be closed
			 * See `onClose` in https://mui.com/material-ui/api/snackbar/#props
			 */
			onClose: () => {
				setState('exiting');
			},
			/*
			 * Consumer is expected to invoke this after the snackbar has completed its transition and is presented
			 * See `TransitionProps` in https://mui.com/material-ui/api/snackbar/#props
			 */
			onEntered: () => {
				setState('entered');
			},
			/*
			 * Consumer is expected to invoke this after the snackbar has completed its transition and is no longer presented
			 * See `TransitionProps` in https://mui.com/material-ui/api/snackbar/#props
			 */
			onExited: () => {
				onDismiss?.();
				setState('exited');
			},
		};
	}, [onDismiss, state]);
};

interface StyledSnackbarProps {
	hasFab?: boolean;
	hasBottomNav?: boolean;
}

const StyledSnackbar = styled(Snackbar, {
	shouldForwardProp: (prop) => prop !== 'hasFab' && prop !== 'hasBottomNav',
})<StyledSnackbarProps>(({ theme, hasFab, hasBottomNav }) => {
	const fabSpacing = 88;
	const bottomNavSpacing = 56;

	/**
	 * If the screen size is mobile, the snackbar will be presented above both the BottomNav and Fab components. These
	 * style decisions were made to replicate the material design page specifications:
	 * https://material.io/components/snackbars#placement
	 */
	const getBottomSpacing = () => {
		if (hasBottomNav && !hasFab) {
			// If we are only accounting for the BottomNav, we will add an extra 8px so that the snackbar doesn't lie directly on top of the BottomNav (8px matches the default Mui Snackbar spacing).
			// We do not need to account for this if the Fab is involved, because that component has padding which is accounted for in its defined fabSpacing variable above.
			return `calc(${bottomNavSpacing}px + ${theme.spacing()})`;
		}

		if (hasFab && hasBottomNav) {
			return fabSpacing + bottomNavSpacing;
		}

		return undefined;
	};

	const bottom = getBottomSpacing();

	return { [theme.breakpoints.down('sm')]: { bottom } };
});

interface NotificationSnackbarProps {
	hasBottomNav?: boolean;
	hasFab?: boolean;
	notifications: Notification[];
	onActionClick?: () => void;
	onDismiss: () => void;
	onOpenDialog: () => void;
}

const NotificationSnackbar = (props: NotificationSnackbarProps) => {
	const { notifications, onActionClick, onDismiss, hasBottomNav, hasFab, onOpenDialog } = props;

	const [notification] = notifications;
	const { autoHideDuration, moreInformation, snackbarAction, text } = notification ?? {};

	const snackbarState = useSnackbarState({ message: notification?.text, onDismiss });

	const handleOpenDialog = () => {
		// open the dialog and dismiss the notification
		onOpenDialog();
		snackbarState.onExited();
	};

	const getSnackbarPlacement = () => {
		if (hasFab) {
			return 'fabAndBottomNav';
		}
		if (hasBottomNav) {
			return 'bottomNav';
		}
		return undefined;
	};

	return (
		<StyledSnackbar
			data-testid="Notifications-snackbar"
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			open={snackbarState.open}
			onClose={snackbarState.onClose}
			ContentProps={{ 'aria-describedby': 'message-id' }}
			autoHideDuration={autoHideDuration}
			message={
				<span data-testid="Notifications-message" id="message-id">
					{text}
				</span>
			}
			action={
				<ActionButton
					snackbarAction={snackbarAction}
					moreInformation={moreInformation}
					onActionClick={onActionClick}
					onMoreInfoClick={handleOpenDialog}
				/>
			}
			TransitionProps={{ onEntered: snackbarState.onEntered, onExited: snackbarState.onExited }}
			// This is here for testing only
			data-placement={getSnackbarPlacement()}
			hasFab={hasFab}
			hasBottomNav={hasBottomNav}
		/>
	);
};

interface NotificationsProps {
	hasBottomNav?: boolean;
	hasFab?: boolean;
	notifications: Notification[];
	onActionClick?: () => void;
	onDismiss: () => void;
}

const Notifications = (props: NotificationsProps) => {
	const { hasBottomNav, hasFab, notifications, onDismiss, onActionClick } = props;

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [presentedNotification, setPresentedNotification] = useState<Notification>();
	const [isCopyToClipboardSnackbarOpen, setisCopyToClipboardSnackbarOpen] = useState(false);
	const [notification] = notifications;

	const onOpenDialog = () => {
		// open the dialog and dismiss the notification
		setPresentedNotification(notification);
		setIsDialogOpen(true);
	};

	const onCloseDialog = () => {
		setIsDialogOpen(false);
		setisCopyToClipboardSnackbarOpen(false);
	};

	const exitedMoreInfo = () => {
		setPresentedNotification(undefined);
	};

	return (
		<>
			<NotificationSnackbar
				notifications={notifications}
				hasBottomNav={hasBottomNav}
				hasFab={hasFab}
				onActionClick={onActionClick}
				onDismiss={onDismiss}
				onOpenDialog={onOpenDialog}
				key={notification?.text}
			/>
			<MoreInfoDialog
				open={isDialogOpen}
				onClose={onCloseDialog}
				TransitionProps={{ onExited: exitedMoreInfo }}
				notification={presentedNotification}
				onCopiedToClipboard={() => {
					setisCopyToClipboardSnackbarOpen(true);
				}}
			>
				<StyledSnackbar
					open={isCopyToClipboardSnackbarOpen}
					onClose={() => {
						setisCopyToClipboardSnackbarOpen(false);
					}}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					ContentProps={{ 'aria-describedby': 'message-id' }}
					message={<span id="message-id">Copied To Clipboard</span>}
					autoHideDuration={6000}
					hasFab={hasFab}
					hasBottomNav={hasBottomNav}
				/>
			</MoreInfoDialog>
		</>
	);
};

export default Notifications;
