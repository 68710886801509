import type { GlobalWithPendo } from '../types';

// Calls Pendo's snippet to install and initialize.
/* istanbul ignore file - Contents of this function provided by pendo. Does not effect user experience. */
const initializePendo = (
	pendoApiKey: string,
	visitor: { id: string; email: string },
	account: { id?: string | null; environment: string },
) => {
	/* eslint-disable */
	(function (apiKey) {
		(function (p, e, n, d: any, o: Record<string, any> = {}) {
			let v: string[];
			let w: number;
			let x: number;
			let y: Record<string, any>;
			let z: Record<string, any>;
			o = p[d] = p[d] || {};
			o._q = [];
			v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
			for (w = 0, x = v.length; w < x; ++w) {
				(function (m) {
					o[m] =
						o[m] ||
						function () {
							o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
						};
				})(v[w]);
			}
			y = e.createElement(n);
			y.async = !0;
			y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
			z = e.getElementsByTagName(n)[0];
			z.parentNode.insertBefore(y, z);
		})(window, document, 'script', 'pendo');
	})(pendoApiKey);

	const { pendo } = global as GlobalWithPendo;

	pendo?.initialize({
		visitor: {
			id: visitor.id, // Required if user is logged in
			email: visitor.email, // Recommended if using Pendo Feedback, or NPS Email
			// full_name:    // Recommended if using Pendo Feedback
			// role:         // Optional

			// You can add any additional visitor level key-values here,
			// as long as it's not one of the above reserved names.
		},

		account: {
			id: account.id ?? '', // Highly recommended
			environment: account.environment, // Deployment environment
			// name:         // Optional
			// is_paying:    // Recommended if using Pendo Feedback
			// monthly_value:// Recommended if using Pendo Feedback
			// planLevel:    // Optional
			// planPrice:    // Optional
			// creationDate: // Optional

			// You can add any additional account level key-values here,
			// as long as it's not one of the above reserved names.
		},
	});
	/* eslint-enable */
};

export default initializePendo;
