import type { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import MuiTooltip from '@mui/material/Tooltip';
import { forwardRef } from 'react';

/** @knipignore - continue exporting the props type */
export interface TooltipProps
	extends Omit<
		MuiTooltipProps,
		| 'components'
		| 'componentsProps'
		| 'PopperComponent'
		| 'TransitionComponent'
		| 'PopperProps'
		| 'TransitionProps'
	> {
	/**
	 * @deprecated `components` is deprecated in v6 and will be removed in v7. It is recommended to use the `slots` prop
	 *   instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#components-13
	 */
	components?: never;
	/**
	 * @deprecated `componentsProps` is deprecated in v6 and will be removed in v7. It is recommended to use the
	 *   `slots.popper` prop instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#componentsprops-15
	 */
	componentsProps?: never;
	/**
	 * @deprecated `PopperComponent` is deprecated in v6 and will be removed in v7. It is recommended to use the
	 *   `PopperComponent` prop instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#component-props-2
	 */
	PopperComponent?: never;
	/**
	 * @deprecated `TransitionComponent` is deprecated in v6 and will be removed in v7. It is recommended to use the
	 *   `TransitionComponent` prop instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#component-props-2
	 */
	TransitionComponent?: never;
	/**
	 * @deprecated `PopperProps` is deprecated in v6 and will be removed in v7. It is recommended to use the
	 *   `slots.transition` prop instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#props-props-3
	 */
	PopperProps?: never;
	/**
	 * @deprecated `PopperProps` is deprecated in v6 and will be removed in v7. It is recommended to use the
	 *   `slots.transition` prop instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#props-props-3
	 */
	TransitionProps?: never;
}

export const Tooltip = forwardRef<HTMLSpanElement, TooltipProps>((props, ref) => (
	<MuiTooltip {...props} ref={ref} />
));
