import { useMutation } from '@apollo/client';
import type {
	InitiatePasswordResetResult,
	InitiatePasswordResetVariables,
} from '#internal/types/graphqlTypes';
import { InitiatePasswordReset } from './mutations.gql';

const useInitiatePasswordReset = () =>
	useMutation<InitiatePasswordResetResult, InitiatePasswordResetVariables>(InitiatePasswordReset);

export default useInitiatePasswordReset;
