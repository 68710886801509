import { useMutation } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { useRefreshAccessToken } from '@storis/app_common-graphql.hooks/useRefreshAccessToken';
import { useRefreshTokenChange } from '@storis/app_common.hooks';
import { useNavigate } from '@storis/app_common.router';
import { decodeToken, isAcceptable, isStale } from '@storis/app_common.utils/jwt';
import {
	clearStorage,
	getStoredAuthWorkspaceName,
	getStoredRefreshToken,
	setStoredAccessToken,
	setStoredIdentity,
	setStoredRefreshToken,
	useAccessToken,
} from '@storis/app_common.utils/storage';
import { encodeURIData } from '@storis/app_common.utils/uri';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { IDTokenJwt } from '#internal/types';
import type {
	AuthenticateEntraIdUserResult,
	AuthenticateEntraIdUserVariables,
} from '#internal/types/graphqlTypes';
import { AuthenticateEntraIdUser } from './mutations.gql';

export type AuthState = 'loading' | 'authenticated' | 'unauthenticated';

interface EntraIdRedirectParams {
	email: string;
	onError: () => void;
}

interface UseEntraIdAuthenticationParams {
	state: AuthState;
	onAuthenticated: () => void;
	onUnauthenticated: () => void;
}

/**
 * Finalize Entra ID login redirect flow by attempting to silently acquire an id token with the provided email.\
 * Returns function to initiate the Entra ID login redirect.
 */
const useEntraIdAuthentication = (params: UseEntraIdAuthenticationParams) => {
	const { state, onAuthenticated } = params;

	const [authenticateEntraIdUser, { data }] = useMutation<
		AuthenticateEntraIdUserResult,
		AuthenticateEntraIdUserVariables
	>(AuthenticateEntraIdUser, { context: { errorMessage: 'Unable to authenticate' } });
	const failureReason =
		data?.userEntraIdAuthenticate?.__typename === 'AuthenticationFailure'
			? data.userEntraIdAuthenticate.reason
			: null;

	const { instance } = useMsal();

	/** Authenticate following a redirect */
	const { idToken } = instance.getActiveAccount() ?? {};

	useEffect(() => {
		if (state === 'unauthenticated' && idToken != null) {
			// authenticate if we've got a workspace and an id token
			const workspaceName = getStoredAuthWorkspaceName();
			if (workspaceName != null && idToken != null) {
				authenticateEntraIdUser({
					variables: { entraIdToken: idToken, workspaceName },
					onCompleted: (results) => {
						if (results.userEntraIdAuthenticate.__typename === 'AuthenticationSuccess') {
							const identity = decodeToken<IDTokenJwt>(results.userEntraIdAuthenticate.idToken);
							setStoredIdentity({
								name: `${identity?.given_name} ${identity?.family_name}`,
								email: identity?.email,
								staffId: identity?.staffId,
								userId: identity?.sub,
							});
							setStoredRefreshToken(results.userEntraIdAuthenticate.refreshToken);
							setStoredAccessToken(results.userEntraIdAuthenticate.accessToken);
							onAuthenticated();
						}
						if (results.userEntraIdAuthenticate.__typename === 'AuthenticationFailure') {
							clearStorage();
						}
					},
				}).catch(() => {});
			}
		}
	}, [authenticateEntraIdUser, idToken, onAuthenticated, state]);

	const onRedirect = useCallback(
		({ email, onError }: EntraIdRedirectParams) => {
			const workspaceName = getStoredAuthWorkspaceName();

			if (workspaceName != null) {
				instance
					.loginRedirect({
						scopes: ['User.Read'],
						loginHint: email,
						extraQueryParameters: { hsu: '1' },
					})
					.catch(() => {
						onError();
					});
			}
		},
		[instance],
	);

	return useMemo(() => {
		return { onRedirect, failureReason };
	}, [failureReason, onRedirect]);
};

interface UseAccessTokenMaintenanceParams {
	onAuthenticated: () => void;
	onUnauthenticated: () => void;
}

/**
 * Create an access token if we've got a refresh token and we not have an acceptable access token.\
 * Returns the access token.
 */
const useAccessTokenMaintenance = (params: UseAccessTokenMaintenanceParams) => {
	const { onAuthenticated, onUnauthenticated } = params;

	const [accessToken, setAccessToken] = useAccessToken();

	// this is used to prevent `refreshAccessToken` from being called while the mutation is in flight.
	const canRefreshAccessToken = useRef<boolean>(true);

	const { refreshAccessToken } = useRefreshAccessToken({
		canRefreshAccessToken,
		onSuccessful: onAuthenticated,
		onUnsuccessful: () => {
			clearStorage();
			onUnauthenticated();
		},
	});

	useEffect(() => {
		const refreshToken = getStoredRefreshToken();
		if (refreshToken == null || !isAcceptable(refreshToken) || isStale(refreshToken)) {
			// If we don't have a good refresh token, the user is unauthorized. Display the login page.
			onUnauthenticated();
			return;
		}

		// if we don't have a good access token, try to get a new one
		if (accessToken == null || !isAcceptable(accessToken)) {
			if (canRefreshAccessToken.current) {
				// prevent this from being called again until the mutation has completed successfully with a valid token
				canRefreshAccessToken.current = false;
				if (accessToken != null) {
					setAccessToken(null);
				}
				refreshAccessToken().catch(() => {});
			}
		} else {
			// we have a good access token
			onAuthenticated();
		}
	}, [accessToken, onAuthenticated, onUnauthenticated, refreshAccessToken, setAccessToken]);

	return accessToken;
};

interface UseAuthStateParams {
	appPath: string;
}

const useAuthState = (params: UseAuthStateParams) => {
	const { appPath } = params;

	const navigate = useNavigate();
	const [state, setState] = useState<AuthState>('loading');

	const onAuthenticated = useCallback(() => {
		setState('authenticated');
	}, []);

	const onUnauthenticated = useCallback(() => {
		setState('unauthenticated');
	}, []);

	const accessToken = useAccessTokenMaintenance({ onAuthenticated, onUnauthenticated });
	const entraId = useEntraIdAuthentication({ state, onAuthenticated, onUnauthenticated });

	// use `window.location.pathname` so the app's path is included
	// using `pathname` from `useLocation` would only provide the path relative to the app since each app defines its own base route
	const appLoginRedirectPath = window.location.pathname;

	const timeout = useCallback(() => {
		// Provide a hash with the proper notification and url to redirect to after logging in
		const hash = {
			notification: 'You have timed out due to inactivity.',
			path: appLoginRedirectPath,
		};

		navigate(`/signout#redirect=${encodeURIData(JSON.stringify(hash))}`)?.catch(() => {});
	}, [navigate, appLoginRedirectPath]);

	/*
	 * Redirect to the app root after signout if the refresh token is cleared.
	 * We will save the current path in the hash so that the
	 * user can be redirected back to the same page after logging in.
	 */
	useRefreshTokenChange({ redirectPath: appPath });

	return useMemo(() => {
		return { accessToken, state, timeout, entraId };
	}, [accessToken, entraId, state, timeout]);
};

export default useAuthState;
