import { useCallback, useEffect, useRef, useState } from 'react';
import createGlobalState from './createGlobalState';

type Element = Window | Document | HTMLElement | null;

/**
 * Internalized use-event-handler v0.1.6 (dependency of use-persisted-state)
 * https://github.com/donavon/use-event-listener/tree/43d647e615b4b02f4800f7fb0f82205e8de7a033
 *
 * This was a dependency which was no longer being maintained so we moved its code into our repository
 */
const useEventListener = (
	eventName: string,
	handler: (event: StorageEvent) => void,
	element: Element = window,
	options: AddEventListenerOptions | undefined = {},
) => {
	const savedHandler = useRef<((event: unknown) => void) | null>(null);
	const { capture, passive, once } = options;

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element?.addEventListener != null;
		if (!isSupported) {
			return () => {};
		}

		const eventListener = (event: unknown) => savedHandler?.current?.(event);
		const opts = { capture, passive, once };
		element.addEventListener(eventName, eventListener, opts);
		return () => {
			element.removeEventListener(eventName, eventListener, opts);
		};
	}, [eventName, element, capture, passive, once]);
};

/**
 * Internalized use-persisted-state v0.3.0
 * https://github.com/donavon/use-persisted-state/tree/3e2ef63f5a357ca136c0b1c3528ed327b5fc9a7e
 *
 * This was a dependency which was no longer being maintained and versions newer than 0.3.0 were causing peer dependency
 * issues, so we moved its code into our repository
 */
const usePersistedState = <TState>(
	key: string,
	{
		get,
		set,
	}: {
		get: (key: string) => TState | undefined;
		set: (key: string, value: TState | undefined) => void;
	},
	subscribeToStorageEvents: boolean,
): [TState | undefined, (newValue: TState | undefined) => void] => {
	const globalState = useRef<ReturnType<typeof createGlobalState> | null>(null);
	const [state, setState] = useState(() => get(key)); // get will default to undefined if there is no value in storage

	// subscribe to `storage` change events
	useEventListener('storage', ({ key: eventStorageKey, newValue }: StorageEvent) => {
		if (!subscribeToStorageEvents) {
			// we don't want to clear sessionStorage data when we receive an event for localStorage being cleared
			return;
		}
		if (eventStorageKey === key) {
			// JSON.parse expects string, but newValue can be null
			const newState = newValue === null ? undefined : JSON.parse(newValue);
			if (state !== newState) {
				setState(newState);
			}
		}
		if (eventStorageKey == null) {
			// when eventStorageKey is null, that means everything is storage was cleared
			setState(undefined);
		}
	});

	// only called on mount
	useEffect(() => {
		// register a listener that calls `setState` when another instance emits
		globalState.current = createGlobalState(key, setState);

		return () => {
			globalState.current?.deregister();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const externalSetState = useCallback((newValue: TState | undefined) => {
		set(key, newValue);
		setState(newValue);
		globalState.current?.emit(newValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [state, externalSetState];
};

const createStorage = (provider: Pick<Storage, 'getItem' | 'setItem' | 'removeItem'>) => {
	return {
		get(key: string, defaultValue?: unknown) {
			const json = provider.getItem(key);
			if (json === null) {
				return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
			}
			return JSON.parse(json);
		},
		set(key: string, value: unknown) {
			if (value === undefined) {
				provider.removeItem(key);
			} else {
				provider.setItem(key, JSON.stringify(value));
			}
		},
	};
};

const createPersistedState = <TState = string>(
	key: string,
	provider: Pick<Storage, 'getItem' | 'setItem' | 'removeItem'> | null = window.localStorage,
): (() => [TState | undefined, (newValue: TState | undefined) => void]) => {
	if (provider) {
		const storage = createStorage(provider);
		return () => usePersistedState<TState>(key, storage, provider !== window.sessionStorage);
	}
	return useState<TState | undefined>;
};

export default createPersistedState;
