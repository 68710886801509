/* istanbul ignore file: this is only used for debugging purposes */
import { ApolloLink, Observable } from '@apollo/client';
import type { ObservableSubscription } from '@apollo/client';
import isOperationDefinitionNode from './isOperationDefinitionNode';

/**
 * This link allows us to delay a query/mutation by a custom amount of time.\
 * Useful for debugging & looking at loading states.\
 * To use, add the `delay` property to the context object with the desired delay in milliseconds. Disabled in production
 * mode.
 */
const delayLink = new ApolloLink((operation, forward) => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const operationType = operation.query.definitions.find(isOperationDefinitionNode)!.operation;

	const { delay } = operation.getContext();

	if (delay == null || operationType === 'subscription' || process.env.NODE_ENV === 'production') {
		// delay should only apply to development mode, and should never apply to subscriptions
		return forward(operation);
	}

	return new Observable((subscriber) => {
		let forwardSubscription: ObservableSubscription | undefined;

		const timeoutId = window.setTimeout(() => {
			forwardSubscription = forward(operation).subscribe({
				next: (data) => {
					subscriber.next(data);
				},
				complete: () => {
					subscriber.complete();
				},
				error: (err) => {
					subscriber.error(err);
				},
			});
		}, delay);

		return () => {
			window.clearTimeout(timeoutId);
			forwardSubscription?.unsubscribe();
		};
	});
});

export default delayLink;
