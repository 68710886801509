import {
	getStoredLastUserActivity,
	setStoredLastUserActivity,
} from '@storis/app_common.utils/storage';
import { throttle } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

interface UseInactivityTimeoutParams {
	activityInterval?: number;
	inactivityPeriod?: number;
	logout: () => void;
	warningPeriod?: number;
	createNotification: (params: { text: string }) => void;
	dismissNotification: () => void;
	getCurrentNotificationMessage: () => string | undefined;
	isAuthenticated: boolean;
}

const useInactivityTimeout = (params: UseInactivityTimeoutParams): void => {
	const {
		activityInterval = 1000, // 1 sec in ms
		inactivityPeriod,
		logout,
		warningPeriod,
		createNotification,
		dismissNotification,
		getCurrentNotificationMessage,
		isAuthenticated,
	} = params;
	const showWarning = useRef(false);
	const intervalId = useRef<number | undefined>();

	// inactivity timeout will not be enforced if any are true:
	// - the user isn't authenticated
	// - timeout settings have been disabled
	// - the settings fetch results in an error
	const timeoutDisabled = !isAuthenticated || inactivityPeriod == null || warningPeriod == null;

	/**
	 * Manage the notification\
	 * Present the notification if the user has been idle too long, dismiss it if they resume activity
	 */
	const manageWarningCallback = useCallback(() => {
		// this callback should hypothetically never get called if timeoutSettings is null
		const warningMessage = `You will be signed out due to inactivity in ${warningPeriod} seconds`;

		if (showWarning.current) {
			createNotification({ text: warningMessage });
		} else {
			const currentNotificationMessage = getCurrentNotificationMessage();

			if (currentNotificationMessage === warningMessage) {
				dismissNotification();
			}
		}
	}, [createNotification, dismissNotification, getCurrentNotificationMessage, warningPeriod]);

	/**
	 * User has timed out\
	 * Clear the activity interval, dismiss the notification, and logout
	 */
	const timeoutCallback = useCallback(() => {
		// stop checking for activity so this is handled once
		clearInterval(intervalId.current);

		showWarning.current = false;
		manageWarningCallback();

		// prior to logout, clear the activity timestamp
		setStoredLastUserActivity(null);
		logout();
	}, [logout, manageWarningCallback]);

	/**
	 * User activity event handler\
	 * Update the stored activity timestamp; user will be timed out if activity is resumed after an excessive idle period
	 */
	const onActivity = useCallback((): void => {
		if (timeoutDisabled) {
			return;
		}
		const lastActivity = getStoredLastUserActivity() ?? 0;
		const idleTime = (Date.now() - lastActivity) / 1000; // convert to seconds to match timeoutSettings

		if (lastActivity !== 0 && idleTime > inactivityPeriod + warningPeriod) {
			// activity was resumed after an excessive period of inactivity
			// e.g. user puts device to sleep for a long time and resumes
			timeoutCallback();
			return;
		}

		setStoredLastUserActivity(Date.now());
	}, [inactivityPeriod, timeoutDisabled, timeoutCallback, warningPeriod]);

	// Add event handlers that are used to determine if the user is active
	useEffect(() => {
		if (timeoutDisabled) {
			return undefined;
		}

		// invoke the event handler before adding listeners to initialize the user as active or time them out
		onActivity();

		const handler = throttle(onActivity, 1000);
		const events = [
			'mousemove',
			'mousedown',
			'keypress',
			'wheel',
			'touchmove',
			'pointermove',
			'mousewheel',
			'DOMMouseScroll',
			'MSPointerMove',
		];

		events.forEach((event) => {
			window.addEventListener(event, handler, false);
		});

		return (): void => {
			events.forEach((event) => {
				window.removeEventListener(event, handler, false);
			});
		};
		// the settings request may be in the process of being fetched, so once the request finishes we will enforce them
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeoutDisabled]);

	// Initialize user activity checks on interval Clears interval when unmounted
	useEffect(() => {
		if (timeoutDisabled) {
			return undefined;
		}
		/** Check user activity; depending on idle time, the user will be warned or timed out */
		const checkLastActivity = (): void => {
			const lastActivity = getStoredLastUserActivity();

			if (lastActivity == null) {
				// no activity to check
				return;
			}
			const idleTime = (Date.now() - lastActivity) / 1000; // convert to seconds to match timeoutSettings
			if (idleTime > inactivityPeriod) {
				if (idleTime > inactivityPeriod + warningPeriod) {
					// user has timed out
					timeoutCallback();
				} else if (!showWarning.current) {
					// user has been idle too long, warn them
					showWarning.current = true;
					manageWarningCallback();
				}
			} else if (showWarning.current) {
				// user has resumed activity following a warning
				showWarning.current = false;
				manageWarningCallback();
			}
		};

		// call checkLastActivity on interval
		intervalId.current = window.setInterval(checkLastActivity, activityInterval);

		/**
		 * Returned cleanup function clears activity interval
		 *
		 * @returns {void}
		 */
		return () => {
			clearInterval(intervalId.current);
		};
	}, [
		activityInterval,
		inactivityPeriod,
		timeoutCallback,
		timeoutDisabled,
		manageWarningCallback,
		warningPeriod,
	]);
};

export default useInactivityTimeout;
