import { RetryLink } from '@apollo/client/link/retry';

const infiniteRetryLink = new RetryLink({
	delay: { initial: 500, max: 60000, jitter: false },
	attempts: {
		max: Infinity,
		retryIf: (error, operation) => operation.getContext().infiniteRetry === true,
	},
});

export default infiniteRetryLink;
