import type {
	FilledTextFieldProps as MuiFilledTextFieldProps,
	OutlinedTextFieldProps as MuiOutlinedTextFieldProps,
	StandardTextFieldProps as MuiStandardTextFieldProps,
	TextFieldProps as MuiTextFieldProps,
	TextFieldVariants,
} from '@mui/material/TextField';
import MuiTextField from '@mui/material/TextField';
import { forwardRef } from 'react';

/** Props that are being overridden for the TextField component. */
interface OverriddenTextFieldProps {
	/**
	 * TextField should not be used as a select.
	 *
	 * Please use `Select` or `NativeSelect` instead.
	 */
	onSelect?: never;
	/**
	 * TextField should not be used as a select.
	 *
	 * Please use `Select` or `NativeSelect` instead.
	 */
	select?: never;
	/**
	 * @deprecated `SelectProps` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slotProps.select` prop instead.
	 *
	 *   See: https://mui.com/material-ui/migration/migrating-from-deprecated-apis/#props-props-2
	 */
	SelectProps?: never;
}

/** @knipignore - continue exporting the props type */
export type FilledTextFieldProps = Omit<MuiFilledTextFieldProps, keyof OverriddenTextFieldProps> &
	OverriddenTextFieldProps;

export type OutlinedTextFieldProps = Omit<
	MuiOutlinedTextFieldProps,
	keyof OverriddenTextFieldProps
> &
	OverriddenTextFieldProps;

export type StandardTextFieldProps = Omit<
	MuiStandardTextFieldProps,
	keyof OverriddenTextFieldProps
> &
	OverriddenTextFieldProps;

export type TextFieldProps<TVariant extends TextFieldVariants = TextFieldVariants> =
	TVariant extends 'filled'
		? FilledTextFieldProps
		: TVariant extends 'standard'
			? StandardTextFieldProps
			: OutlinedTextFieldProps;

/** A wrapper around the MUI TextField component that will only behave as a text field with no select functionality. */
export const TextField = forwardRef<HTMLDivElement, MuiTextFieldProps>((props, ref) => (
	<MuiTextField {...props} ref={ref} />
));
