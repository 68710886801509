import { useMemo, useState } from 'react';

/** This hook is intended to be used with the BotDetection component. */
const useBotDetection = () => {
	const [challengeResponse, setChallengeResponse] = useState<string | null>(null);
	const [status, setStatus] = useState<'solved' | 'error' | 'pending'>('pending');
	const [errorCode, setErrorCode] = useState<string | null>(null);
	const [isInteractive, setIsInteractive] = useState(false);

	const handleSuccess = (token: string) => {
		setErrorCode(null);
		setChallengeResponse(token);
		setStatus('solved');
	};

	const handleError = (error: string) => {
		setErrorCode(error);
		setChallengeResponse(null);
		setStatus('error');
	};

	const handleExpire = () => {
		setErrorCode(null);
		setChallengeResponse(null);
		setStatus('pending');
	};

	const handleBeforeInteractive = () => {
		setIsInteractive(true);
	};

	const handleReset = () => {
		setErrorCode(null);
		setIsInteractive(false);
		setChallengeResponse(null);
		setStatus('pending');
	};

	return useMemo(() => {
		return {
			challengeResponse,
			errorCode,
			isInteractive,
			status,
			handleBeforeInteractive,
			handleSuccess,
			handleError,
			handleExpire,
			handleReset,
		};
	}, [challengeResponse, errorCode, isInteractive, status]);
};

export default useBotDetection;
