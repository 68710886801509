import { onError } from '@apollo/client/link/error';

interface MakeInvalidAccessTokenDetectorLinkParams {
	setAccessToken?: (accessToken: string | null) => void;
}

const makeInvalidAccessTokenDetectorLink = ({
	setAccessToken,
}: MakeInvalidAccessTokenDetectorLinkParams) =>
	onError(({ networkError, operation, forward }) => {
		if (
			networkError != null &&
			'statusCode' in networkError &&
			'result' in networkError &&
			networkError.statusCode === 401 &&
			typeof networkError.result === 'object' &&
			networkError.result.name === 'InvalidAccessTokenError' &&
			setAccessToken != null
		) {
			/*
			 * 401 Unauthorized due to invalid access token
			 * The services are informing us that our access token is invalid for some reason
			 * Since the next link in our apollo client specifically handles refreshing our access token
			 * and handles the possibility of not having an access token, we're going to scrap ours and let	the next link fetch a new one
			 */
			setAccessToken(null);
			return forward(operation);
		}

		return undefined;
	});

export default makeInvalidAccessTokenDetectorLink;
