import { SignOutPage } from '@storis/app_common-graphql.components/SignOutPage';
import { createBrowserRouter, createRoutesFromElements, Route } from '@storis/app_common.router';
import { NextGenHome } from '#features/NextGenHome';
import { NotFoundPage } from '#features/NotFoundPage';

interface MakeRouterParams {
	children: React.ReactElement;
	ErrorBoundary?: React.ComponentType | null;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const makeRouter = ({ children, ErrorBoundary }: MakeRouterParams) =>
	createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={children} ErrorBoundary={ErrorBoundary}>
				<Route path="" element={<NextGenHome />} />
				<Route path="signout" element={<SignOutPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Route>,
		),
	);

export default makeRouter;
