import {
	CircularProgress,
	IconButton,
	InputAdornment,
	TextField,
} from '@storis/app_common.ui/components';
import type { IconButtonProps, TextFieldProps } from '@storis/app_common.ui/components';
import { ClearIcon, VisibilityIcon, VisibilityOffIcon } from '@storis/app_common.ui/icons';
import type { IconComponentType, SvgIconProps } from '@storis/app_common.ui/icons';
import { useCallback, useMemo, useState } from 'react';
import type React from 'react';
import type { Control, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';

interface UseTextFieldPropsParams {
	type: TextFieldProps['type'];
}

interface UseTextFieldPropsResult {
	inputProps?: Partial<TextFieldProps['inputProps']>;
}

const useTextFieldProps = (props: UseTextFieldPropsParams): UseTextFieldPropsResult => {
	const { type } = props;

	if (type === 'email') {
		return {
			/**
			 * These properties must be specified in `inputProps` even though `TextField` has them see:
			 * https://github.com/mui/material-ui/issues/27833
			 */
			inputProps: { inputMode: 'email', autoCapitalize: 'none', spellCheck: 'false' },
		};
	}

	if (type === 'password') {
		return { inputProps: { autoCapitalize: 'none', spellCheck: 'false' } };
	}

	return {};
};

interface UseControlledTextFieldParams<TFieldValues extends FieldValues> {
	name: FieldPath<TFieldValues>;
	control: Control<TFieldValues>;
	rules?: UseControllerProps<TFieldValues>['rules'];
	onChange?: (value: string) => void;
	format?: (value?: string | null, previousValue?: string | null) => string;
	shouldInvokeFieldOnChange: boolean;
}

const useControlledTextField = <TFieldValues extends FieldValues>(
	params: UseControlledTextFieldParams<TFieldValues>,
) => {
	const {
		name,
		control,
		onChange: onChangeParam,
		format,
		shouldInvokeFieldOnChange = true,
		rules,
	} = params;
	const {
		field,
		fieldState: { error },
		formState,
	} = useController({ name, control, rules });

	const { isSubmitting } = formState;
	const errorMessage = error?.message;
	const displayValue = format ? format(field.value) : field.value;

	return useMemo(() => {
		return {
			errorMessage,
			disabled: isSubmitting,
			onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
				const formattedValue = format
					? format(event.target.value, field.value)
					: event.target.value;

				if (shouldInvokeFieldOnChange) {
					field.onChange(formattedValue);
				}
				onChangeParam?.(formattedValue);
			},
			onClear: () => {
				if (shouldInvokeFieldOnChange) {
					field.onChange('');
				}
				onChangeParam?.('');
			},
			field,
			// this is a controlled input, so the value should never be null or undefined
			value: displayValue ?? '',
		};
	}, [
		errorMessage,
		displayValue,
		isSubmitting,
		field,
		format,
		shouldInvokeFieldOnChange,
		onChangeParam,
	]);
};

interface ProtectedTextFieldAdornmentProps {
	isHidden: boolean;
	onClick: () => void;
}

const ProtectedTextFieldAdornment = (props: ProtectedTextFieldAdornmentProps) => {
	const { isHidden, onClick } = props;

	return (
		<InputAdornment position="end">
			<IconButton aria-label="toggle visibility" onClick={onClick} edge="end">
				{isHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
			</IconButton>
		</InputAdornment>
	);
};

/**
 * Omit select and SelectProps from TextFieldProps to keep this component a text field only.\
 * There is already a common comp. NativeSelectField for select fields.
 */
export type ControlledTextFieldProps<TFieldValues extends FieldValues> = Omit<
	TextFieldProps,
	'onChange' | 'select' | 'SelectProps'
> & {
	name: FieldPath<TFieldValues>;
	control: Control<TFieldValues>;
	endAdornmentVariant?: 'clearable' | 'restricted';
	format?: (value?: string | null, previousValue?: string | null) => string;
	onChange?: (value: string) => void;
	ClearIconButtonProps?: IconButtonProps;
	ClearIconProps?: SvgIconProps;
	loading?: boolean;
	/** Override the field's error message, presented as field error helper text */
	errorMessage?: string;
	/** If false, `field.onChange` will not be used and the field will be managed by the consumer */
	shouldInvokeFieldOnChange?: false;
	rules?: UseControllerProps<TFieldValues>['rules'];
	StartAdornmentIcon?: IconComponentType;
};

const ControlledTextField = <TFieldValues extends FieldValues = FieldValues>(
	props: ControlledTextFieldProps<TFieldValues>,
) => {
	const {
		name,
		control,
		disabled: disabledProp,
		endAdornmentVariant,
		format,
		onChange: onChangeProp,
		helperText,
		InputProps,
		ClearIconButtonProps,
		ClearIconProps,
		variant = 'outlined',
		loading,
		errorMessage: providedErrorMessage,
		shouldInvokeFieldOnChange = true,
		rules,
		StartAdornmentIcon,
		slotProps: providedSlotProps,
		onFocus,
		onBlur,
		sx,
		...textFieldProps
	} = props;
	const { errorMessage, disabled, field, onChange, onClear, value } = useControlledTextField({
		name,
		format,
		control,
		onChange: onChangeProp,
		shouldInvokeFieldOnChange,
		rules,
	});
	const onMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const fieldErrorMessage = providedErrorMessage ?? errorMessage;
	const fieldHelperText = fieldErrorMessage ?? helperText;
	const hasError = fieldErrorMessage != null;

	const [isRestrictedFieldHidden, setIsRestrictedFieldHidden] = useState(true);
	const handleRestrictedFieldButtonClick = () => {
		setIsRestrictedFieldHidden((hidden) => !hidden);
	};
	const endAdornment = useMemo(() => {
		if (loading) {
			return (
				<InputAdornment position="end">
					<CircularProgress size={20} />
				</InputAdornment>
			);
		}

		if (endAdornmentVariant === 'restricted') {
			return (
				<ProtectedTextFieldAdornment
					isHidden={isRestrictedFieldHidden}
					onClick={handleRestrictedFieldButtonClick}
				/>
			);
		}

		if (endAdornmentVariant === 'clearable' && value !== '') {
			return (
				<InputAdornment position="end">
					<IconButton
						onClick={onClear}
						onMouseDown={onMouseDown}
						edge="end"
						aria-label="clear"
						disabled={disabled || disabledProp}
						{...ClearIconButtonProps}
					>
						<ClearIcon {...ClearIconProps} />
					</IconButton>
				</InputAdornment>
			);
		}

		return undefined;
	}, [
		ClearIconButtonProps,
		ClearIconProps,
		disabled,
		disabledProp,
		endAdornmentVariant,
		loading,
		isRestrictedFieldHidden,
		onClear,
		value,
	]);

	const type =
		endAdornmentVariant === 'restricted' && isRestrictedFieldHidden
			? 'password'
			: textFieldProps.type;

	const { inputProps } = useTextFieldProps({ type });

	const [shrink, setShrink] = useState(false);
	const hasStartAdornmentIcon = StartAdornmentIcon != null;
	const handleFocus = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			onFocus?.(event);
			if (hasStartAdornmentIcon) {
				setShrink(true);
			}
		},
		[hasStartAdornmentIcon, onFocus],
	);
	const handleBlur = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			field.onBlur();
			onBlur?.(event);
			if (hasStartAdornmentIcon && !event.target.value) {
				setShrink(false);
			}
		},
		[field, hasStartAdornmentIcon, onBlur],
	);

	const slotProps = useMemo(() => {
		if (hasStartAdornmentIcon) {
			return {
				...providedSlotProps,
				input: {
					...providedSlotProps?.input,
					startAdornment: (
						<InputAdornment position="start" sx={{ pointerEvents: 'none' }}>
							<StartAdornmentIcon color={hasError && value === '' ? 'error' : undefined} />
						</InputAdornment>
					),
				},
				inputLabel: { ...(providedSlotProps?.inputLabel ?? {}), shrink: value !== '' || shrink },
			};
		}

		return providedSlotProps;
	}, [StartAdornmentIcon, hasError, hasStartAdornmentIcon, shrink, providedSlotProps, value]);

	return (
		<TextField
			variant={variant}
			onBlur={handleBlur}
			onChange={onChange}
			value={value}
			inputRef={field.ref}
			disabled={disabled || disabledProp}
			error={hasError}
			helperText={!loading ? fieldHelperText : undefined}
			InputProps={{ endAdornment, ...InputProps }}
			// eslint-disable-next-line react/jsx-no-duplicate-props
			inputProps={{ ...textFieldProps.inputProps, ...inputProps }}
			type={type}
			name={name}
			onFocus={handleFocus}
			slotProps={slotProps}
			sx={
				hasStartAdornmentIcon
					? {
							...sx,
							'& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
								// 48px leaves space for the icon
								// 17px vertically centers the label
								transform: 'translate(48px, 17px)',
							},
						}
					: sx
			}
			{...textFieldProps}
		/>
	);
};

export default ControlledTextField;
