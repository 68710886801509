import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import type { Notification } from '@storis/app_common.components';
import { ExternalPageLayout } from '@storis/app_common.components';
import { useRedirectHashNotification } from '@storis/app_common.hooks';
import { Box, CircularProgress } from '@storis/app_common.ui/components';
import { getStoredAuthWorkspaceName, getStoredIdentity } from '@storis/app_common.utils/storage';
import { useEffect, useState } from 'react';
import type { AuthenticationFailureReason } from '#internal/types/graphqlTypes';
import { CreatePasswordPromptCard } from './CreatePasswordPromptCard';
import { EnterPasswordCard } from './EnterPasswordCard';
import { PasswordEmailSentCard } from './PasswordEmailSentCard';
import SignInCard from './SignInCard';
import type { OnWorkspaceAndEmailSuccessParams } from './WorkspaceAndEmailCard';
import { WorkspaceAndEmailCard } from './WorkspaceAndEmailCard';

interface EntraIdRedirectParams {
	email: string;
	onError: () => void;
}

interface SignInProps {
	turnstileSiteKey: string;
	createNotification: (payload: Notification) => void;
	entraId: {
		onRedirect: ({ email, onError }: EntraIdRedirectParams) => void;
		failureReason: AuthenticationFailureReason | null;
	};
}

const SignIn = (props: SignInProps) => {
	const { turnstileSiteKey, createNotification, entraId } = props;

	// if entra authentication is in progress, initialize view to `entraId`
	const { inProgress } = useMsal();
	const [view, setView] = useState<
		'initial' | 'onboarding' | 'password' | 'entraId' | 'confirmation'
	>(() => (inProgress === InteractionStatus.HandleRedirect ? 'entraId' : 'initial'));

	useEffect(() => {
		if (view === 'entraId' && entraId.failureReason != null) {
			setView('initial');
		}
	}, [entraId.failureReason, view]);

	// present a notification when the redirect hash contains one
	useRedirectHashNotification({ createNotification });

	const workspaceName = getStoredAuthWorkspaceName();
	const { email: storedEmail } = getStoredIdentity() ?? {};

	const handleWorkspaceAndEmailSuccess = (params: OnWorkspaceAndEmailSuccessParams) => {
		const { userAuthenticationMethod, email } = params;
		if (userAuthenticationMethod === 'entraId') {
			setView('entraId');
			entraId.onRedirect({
				email,
				onError: () => {
					setView('initial');
				},
			});
		} else {
			setView(userAuthenticationMethod);
		}
	};

	return (
		<ExternalPageLayout>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				{view === 'initial' && (
					<WorkspaceAndEmailCard
						onSuccess={handleWorkspaceAndEmailSuccess}
						failureReason={entraId.failureReason}
					/>
				)}
				{/* workspaceName and email will be set in the workspaceAndEmail view */}
				{view === 'onboarding' && workspaceName != null && storedEmail != null && (
					<CreatePasswordPromptCard
						workspaceName={workspaceName}
						email={storedEmail}
						turnstileSiteKey={turnstileSiteKey}
						onSuccess={() => {
							setView('confirmation');
						}}
					/>
				)}
				{view === 'confirmation' && workspaceName != null && storedEmail != null && (
					<PasswordEmailSentCard
						workspaceName={workspaceName}
						email={storedEmail}
						turnstileSiteKey={turnstileSiteKey}
						createNotification={createNotification}
					/>
				)}
				{view === 'password' && workspaceName != null && storedEmail != null && (
					<EnterPasswordCard
						workspaceName={workspaceName}
						email={storedEmail}
						onForgotPassword={() => {
							setView('onboarding');
						}}
					/>
				)}
				{view === 'entraId' && (
					<SignInCard title="Sign in to your account">
						<Box
							sx={{
								minHeight: 180,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<CircularProgress />
						</Box>
					</SignInCard>
				)}
			</Box>
		</ExternalPageLayout>
	);
};

export default SignIn;
