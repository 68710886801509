import { styled } from '@storis/app_common.ui/components';
import type { ButtonProps, IconButtonProps } from '@storis/app_common.ui/components';
import type React from 'react';
import { useRef } from 'react';
import CopyIconButton from './CopyIconButton';

const HiddenTextArea = styled('textarea')({ position: 'fixed', left: -9999 });

interface CopyToClipboardButtonProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any; // all other props will be spread to the Component param
	component?: React.FC<ButtonProps | IconButtonProps>;
	value: string | undefined;
	onCopiedToClipboard?: () => void;
}

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
	const { component: Component = CopyIconButton, value, onCopiedToClipboard, ...rest } = props;
	const textInput = useRef<HTMLTextAreaElement>(null);

	const onClick = (): void => {
		// when value is null, there is no value to copy
		// we will disable the component or copy button to make it non-clickable. therefore, the else branch will not be hit.
		// We will ignore the else branch for code coverage
		if (textInput.current != null) {
			textInput.current.disabled = false;
			textInput.current.select();
			document.execCommand('copy');
			textInput.current.disabled = true;
			if (onCopiedToClipboard != null) {
				onCopiedToClipboard();
			}
		}
	};

	return (
		<div>
			<HiddenTextArea aria-hidden="true" value={value} disabled ref={textInput} />
			<Component onClick={onClick} {...rest} disabled={value == null} />
		</div>
	);
};

export default CopyToClipboardButton;
