import { gql } from '@apollo/client';

export const useIsWorkspaceAdmin_MeFragment = gql`
	fragment useIsWorkspaceAdmin_MeFragment on Me {
		id
		permissions {
			name
		}
	}
`;
