import { ApolloLink, Observable } from '@apollo/client';

let pendingAccessTokenRequest: Promise<void> | null = null;

interface FetchAccessTokenParams {
	graphqlApi: string;
	refreshToken: string;
	setAccessToken: (accessToken: string | null) => void;
	setRefreshToken: (refreshToken: string | null) => void;
	getRefreshToken: () => string | null | undefined;
	getAccessToken: () => string | null | undefined;
	isAccessTokenAcceptable: (token: string) => boolean;
}

// these mutations manage their own authentication
const mutationsToForward = new Set([
	'adminUserEntraIdAuthenticate',
	'accessTokenRefresh',
	'userSetPassword',
	'userCancelPasswordReset',
	'userInitiatePasswordReset',
	'userPasswordAuthenticate',
	'userEntraIdAuthenticate',
]);

// these queries manage their own authentication
const queriesToForward = new Set(['userAuthenticationMethod']);

const fetchAccessToken = async (params: FetchAccessTokenParams) => {
	const {
		graphqlApi,
		setAccessToken,
		setRefreshToken,
		getRefreshToken,
		getAccessToken,
		isAccessTokenAcceptable,
	} = params;

	await navigator.locks.request('refresh_access_token', async () => {
		const refreshToken = getRefreshToken();
		const accessToken = getAccessToken();

		if (accessToken != null && isAccessTokenAcceptable(accessToken)) {
			// access token has been created by a previous request and is acceptable
			return accessToken;
		}

		// Timeout request after 30 seconds.
		// This matches our default timeout for all other GraphQL requests.
		// This also helps ensure that the timestamp on the received token is accurate within 30s.
		const abortController = new AbortController();
		const timeoutId = setTimeout(() => {
			abortController.abort();
		}, 30000);

		const response = await fetch(`${graphqlApi}?op=RefreshAccessTokenLink`, {
			method: 'POST',
			signal: abortController.signal,
			headers: { accept: '*/*', 'content-type': 'application/json' },
			body: JSON.stringify({
				operationName: 'RefreshAccessTokenLink',
				variables: { refreshToken },
				query: `mutation RefreshAccessTokenLink($refreshToken: String!) { accessTokenRefresh(refreshToken: $refreshToken) { ... on AccessTokenRefreshSuccess { refreshToken accessToken } ... on AuthenticationFailure { reason } } }`,
			}),
		});

		clearTimeout(timeoutId);

		if (response.ok) {
			const {
				data: { accessTokenRefresh },
			} = await response.json();

			if (accessTokenRefresh.accessToken == null || accessTokenRefresh.refreshToken == null) {
				throw new Error('Failed to refresh access token');
			}

			setAccessToken(accessTokenRefresh.accessToken);
			setRefreshToken(accessTokenRefresh.refreshToken);
			return accessTokenRefresh.accessToken;
		}

		throw new Error('Failed to refresh access token');
	});
};

export interface MakeTokenRefreshLinkParams {
	getRefreshToken: () => string | null | undefined;
	getAccessToken: () => string | null | undefined;
	setAccessToken: (accessToken: string | null) => void;
	setRefreshToken: (refreshToken: string | null) => void;
	isAccessTokenAcceptable: (accessToken: string) => boolean;
	isRefreshTokenAcceptable: (refreshToken: string) => boolean;
	unableToRefreshAccessToken?: () => void;
	graphqlApi: string;
}

const makeTokenRefreshLink = (params: MakeTokenRefreshLinkParams) => {
	const {
		graphqlApi,
		getRefreshToken,
		getAccessToken,
		isAccessTokenAcceptable,
		isRefreshTokenAcceptable,
		setAccessToken,
		setRefreshToken,
		unableToRefreshAccessToken,
	} = params;

	return new ApolloLink((operation, forward) => {
		const refreshToken = getRefreshToken();
		const accessToken = getAccessToken();

		// don't refresh access tokens for mutations or queries we should forward
		const isSkipped = operation.query?.definitions?.some((definition) => {
			if (definition.kind === 'OperationDefinition') {
				if (definition.operation === 'mutation') {
					return definition.selectionSet.selections.some(
						(selection) =>
							selection.kind === 'Field' && mutationsToForward.has(selection.name.value),
					);
				}
				if (definition.operation === 'query') {
					return definition.selectionSet.selections.some(
						(selection) => selection.kind === 'Field' && queriesToForward.has(selection.name.value),
					);
				}
			}
			return false;
		});

		if (isSkipped) {
			return forward(operation);
		}

		if (accessToken != null && isAccessTokenAcceptable(accessToken)) {
			// access token is good to go
			return forward(operation);
		}

		if (refreshToken == null || !isRefreshTokenAcceptable(refreshToken)) {
			// no refresh token, or refresh token is not acceptable
			if (unableToRefreshAccessToken != null) {
				unableToRefreshAccessToken();
			}
			return new Observable((observer) => {
				observer.error(new Error('Refresh Token unacceptable'));
			});
		}

		// access token needs to be refreshed
		pendingAccessTokenRequest ??= fetchAccessToken({
			graphqlApi,
			refreshToken,
			setAccessToken,
			setRefreshToken,
			getRefreshToken,
			getAccessToken,
			isAccessTokenAcceptable,
		});

		// return a promise that resolves when the access token is refreshed
		return new Observable((observer) => {
			// pendingAccessTokenRequest is non-null at this point
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			pendingAccessTokenRequest!
				.then(() => {
					// access token has been refreshed, so forward the operation
					forward(operation).subscribe({
						next: (result) => {
							observer.next(result);
						},
						error: (error) => {
							observer.error(error);
						},
						complete: () => {
							observer.complete();
						},
					});
				})
				.catch((error) => {
					// access token refresh failed
					if (unableToRefreshAccessToken != null) {
						unableToRefreshAccessToken();
					}
					observer.error(error);
				})
				.finally(() => {
					pendingAccessTokenRequest = null;
				});
		});
	});
};

export default makeTokenRefreshLink;
