import getStoredClockDrift from '../storage/getStoredClockDrift';
import decodeToken from './decodeToken';
import type { Jwt } from './types';

/** Determine whether a well-formed JWT has aged beyond the specified threshold */
const isStale = (jwt: string, threshold = 0.5): boolean => {
	const decodedJwt = decodeToken<Jwt>(jwt);
	if (decodedJwt == null) {
		// tokens that aren't decoded into an object are unacceptable
		// consider them stale
		return true;
	}

	// determine lifespan of token
	// use of `iat` and `exp` claims is optional: https://tools.ietf.org/html/rfc7519#section-4.1
	const { iat, exp } = decodedJwt;
	if (exp == null) {
		// token will never expire
		return false;
	}
	if (iat == null) {
		// unable to determine lifespan, if we're checking for stale tokens, we expect a lifespan
		return true;
	}

	// subtract expiry from issuance to get the lifespan, multiply by threshold to get our duration
	const lifespan = exp - iat;
	const toleratedDuration = lifespan * threshold;
	const clockDrift = getStoredClockDrift() ?? 0;

	// if we have passed the threshold, the token is stale
	return iat + toleratedDuration <= new Date().getTime() / 1000 - clockDrift;
};

export default isStale;
