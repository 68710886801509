import type { Breakpoint } from '@storis/app_common.ui/components';
import { LinearProgress, styled } from '@storis/app_common.ui/components';

interface ProgressContainerProps {
	children: React.ReactNode;
	className?: string;
	/**
	 * `isLoading` (default `false`)\
	 * If `true` an absolutely positioned linear progressbar will be presented below the `Heading` (or at the top, if
	 * there is no `Heading`)
	 */
	isLoading?: boolean;
	/**
	 * `rightPanelPinnedBreakpoint` (default `undefined`)\
	 * The breakpoint at which the right panel will be pinned to the right side of the page
	 */
	rightPanelPinnedBreakpoint?: Breakpoint;
}

const AbsoluteLinearProgress = styled(LinearProgress)(({ theme }) => {
	return {
		// Ensure that the linear progress bar is visible in all situations where the app bar is visible, but covered by its shadow
		zIndex: theme.zIndex.appBar - 1,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
	};
});

const ProgressContainerRoot = styled('div', {
	shouldForwardProp: (prop) => prop !== 'rightPanelPinnedBreakpoint',
})<Pick<ProgressContainerProps, 'rightPanelPinnedBreakpoint'>>(({
	theme,
	rightPanelPinnedBreakpoint,
}) => {
	return {
		position: 'relative',
		minHeight: 8,
		...(rightPanelPinnedBreakpoint != null
			? {
					/*
					 * The page will present a pinned right panel at the specified breakpoint
					 * Allow this element to be scrollable if the content overflows
					 */
					overflow: 'auto',
					/*
					 * This element is being rendered in a columnar flex container and it needs to flex and grow to fill the
					 * remaining space
					 */
					[theme.breakpoints.up(rightPanelPinnedBreakpoint)]: {
						display: 'flex',
						flexGrow: 1,
						flexDirection: 'column',
					},
				}
			: {
					/*
					 * There will be no pinned right panel, so this element should not scroll Allowing `overflow: auto` will cut
					 * off box-shadows, preventing them from being rendered outside of this element
					 */
					overflow: 'unset',
				}),
	};
});

const ProgressContainer = (props: ProgressContainerProps) => {
	const { children, className, isLoading = false, rightPanelPinnedBreakpoint } = props;

	return (
		<ProgressContainerRoot
			rightPanelPinnedBreakpoint={rightPanelPinnedBreakpoint}
			className={className}
		>
			{/* The use of an absolutely positioned progress bar allows it to be presented and removed without shifting page contents */}
			{isLoading ? <AbsoluteLinearProgress /> : null}
			{children}
		</ProgressContainerRoot>
	);
};

export default ProgressContainer;
