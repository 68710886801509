import { useMemo, useState } from 'react';

type UseToggleSwitchResult = [
	state: boolean,
	handlers: { on: () => void; off: () => void; toggle: () => void },
];

const useToggleSwitch = (initialValue = false): UseToggleSwitchResult => {
	const [value, setValue] = useState(initialValue);
	const handlers = useMemo(() => {
		return {
			on: () => {
				setValue(true);
			},
			off: () => {
				setValue(false);
			},
			toggle: () => {
				setValue((v) => !v);
			},
		};
	}, []);
	return [value, handlers];
};

export default useToggleSwitch;
