import { createSlice } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import type { CreateAction, NotificationsState } from './types';

const initialState: NotificationsState = [];

const {
	name,
	reducer,
	actions: { create, dismiss, snackbarActionClicked },
} = createSlice({
	reducers: {
		snackbarActionClicked: (state) => {
			if (state.length > 0) {
				state[0].clicks = (state[0].clicks ?? 0) + 1;
			}
		},
		create: (state, action: CreateAction) => {
			// apply a default autoHideDuration of 5 seconds
			state.push(castDraft({ autoHideDuration: 5000, ...action.payload }));
		},
		dismiss: (state) => {
			state.shift();
		},
	},
	initialState,
	name: 'notifications',
});

const getNotifications = ({ notifications }: { notifications: NotificationsState }) =>
	notifications;

export { initialState, name, reducer, create, dismiss, snackbarActionClicked, getNotifications };
