import type { Dispatch, SetStateAction } from 'react';

type GlobalState<TState> = Record<string, { callbacks: Dispatch<SetStateAction<TState>>[] }>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const globalState: GlobalState<any> = {};

const createGlobalState = <TState>(key: string, thisCallback: Dispatch<SetStateAction<TState>>) => {
	globalState[key] ??= { callbacks: [] };
	globalState[key].callbacks.push(thisCallback);
	return {
		deregister() {
			const arr = globalState[key].callbacks;
			const index = arr.indexOf(thisCallback);
			if (index > -1) {
				arr.splice(index, 1);
			}
		},
		emit(value: unknown) {
			globalState[key].callbacks.forEach((callback) => {
				if (thisCallback !== callback) {
					callback(value);
				}
			});
		},
	};
};

export default createGlobalState;
