import { Alert } from '@storis/app_common.ui/components';
import type { AuthenticationFailureReason } from '#internal/types/graphqlTypes';

const errorMessagesMap: Record<AuthenticationFailureReason, string> = {
	missingWorkspace:
		'The workspace name is incorrect or does not exist. Verify the workspace name and try again.',
	disabledWorkspace: 'The workspace is disabled. Contact your system administrator.',
	unsupportedERPVersion:
		'The STORIS ERP version is not supported by this application. Contact your system administrator.',
	invalidIdentityCredentials: 'Incorrect email or password.',
	disabledIdentity: 'This account is disabled. Contact your system administrator.',
	unknown: 'An unexpected error occurred. Try again.',
};

interface AuthenticationFailureProps {
	reason?: AuthenticationFailureReason | null;
}

const AuthenticationFailure = (props: AuthenticationFailureProps) => {
	const { reason } = props;

	if (reason == null) {
		return null;
	}

	return <Alert severity="error">{errorMessagesMap[reason]}</Alert>;
};

export default AuthenticationFailure;
