/* istanbul ignore file: used for mocks */
import type { FieldReadFunction, TypePolicies } from '@apollo/client';
import type { LaunchDarklyClientFlags as Flags, LDClient } from '@storis/app_common.feature_flags';

const isTestEnv = process.env.NODE_ENV === 'test';

/**
 * @knipignore
 * This directive should replace the @client directive in order for us to mock any temporary cache resolvers
 * This is ignored by knip because it is only used when there are temporary mocks in place
 */
export const mockClientDirective = isTestEnv ? '' : '@client';

export const mockTypePolicies = (ldClientRef: { current: LDClient | null }): TypePolicies => {
	interface MakeFlaggedMockReadParams<TFlagName extends keyof Flags> {
		flagName: TFlagName;
		activeValue: Flags[TFlagName];
		func: FieldReadFunction;
	}

	interface MakeFlaglessMockReadParams {
		flagName?: never;
		activeValue?: never;
		func: FieldReadFunction;
	}

	type MakeMockReadParams<TFlagName extends keyof Flags> =
		| MakeFlaggedMockReadParams<TFlagName>
		| MakeFlaglessMockReadParams;

	// @ts-expect-error: makeMockRead not currently in use
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const makeMockRead = <TFlagName extends keyof Flags>(
		params: MakeMockReadParams<TFlagName>,
	): FieldReadFunction => {
		const { flagName, activeValue, func } = params;

		return (val, options) => {
			if (isTestEnv) {
				return val;
			}

			if (flagName == null) {
				return func(val, options);
			}

			if (ldClientRef.current?.variation(flagName) === activeValue) {
				return func(val, options);
			}
			return val ?? null;
		};
	};

	return {
		Mutation: {
			fields: {
				relationshipAddAssignee: {
					merge: (existing, incoming, { cache }) => {
						// remove relationship list results from cache to force refetch
						cache.evict({ fieldName: 'relationships' });
						cache.gc();
						return incoming;
					},
				},
				relationshipRemoveAssignee: {
					merge: (existing, incoming, { cache }) => {
						// remove relationship list results from cache to force refetch
						cache.evict({ fieldName: 'relationships' });
						cache.gc();
						return incoming;
					},
				},
			},
		},
	};
};
