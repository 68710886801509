import encodeURIData from './encodeURIData';

interface MakeURIHashParams {
	logout?: boolean;
	url?: string;
	notification?: string;
}

/** Construct a URI hash from the given options */
const makeURIHash = (options: MakeURIHashParams) => {
	const { logout, url, notification } = options;

	// Construct the redirect hash removing any undefined values
	const hash = JSON.parse(JSON.stringify({ logout: logout ? true : undefined, url, notification }));

	// encode the hash if there is any data
	return Object.keys(hash).length > 0 ? encodeURIData(hash) : undefined;
};

export default makeURIHash;
