import {
	AppBar,
	Box,
	Dialog,
	DialogContent,
	LinearProgress,
	Toolbar,
	Typography,
} from '@storis/app_common.ui/components';
import type { DialogContentProps } from '@storis/app_common.ui/components';

interface SinglePageLayoutProps {
	title: string;
	children?: React.ReactNode;
	appBarButton?: React.ReactNode;
	isProcessing?: boolean;
	labelId?: string;
	scrollRef?: DialogContentProps['ref'];
}

const SinglePageLayout = (props: SinglePageLayoutProps) => {
	const { appBarButton, children, title, isProcessing, labelId, scrollRef } = props;
	return (
		<Dialog
			disableRestoreFocus
			fullScreen
			open
			transitionDuration={0}
			aria-labelledby={labelId}
			// grey.100 matches the global body background color specified in StorisThemeProvider
			sx={{ '& .MuiDialog-paper': { backgroundColor: 'grey.100' } }}
		>
			{/* This zIndex ensures the shadow and progressbar appear on top of the content */}
			<Box sx={{ position: 'relative', zIndex: 1 }}>
				{/* This zIndex ensures the shadow appears on top of the progressbar */}
				<AppBar position="relative" sx={{ zIndex: 1 }}>
					<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant="h6" id={labelId}>
							{title}
						</Typography>
						{appBarButton}
					</Toolbar>
				</AppBar>
				{isProcessing && (
					<LinearProgress sx={{ position: 'absolute', left: 0, right: 0, top: '100%' }} />
				)}
			</Box>
			{/* Setting zIndex on DialogContent creates a new stacking context which ensures
			    that nothing in children can accidentally appear above the shadow/progressbar
				no matter what zIndex the consumer uses.
				(exception: Portals, such as MUI Dialog, can appear on top) */}
			<DialogContent
				sx={{ p: 0, position: 'relative', zIndex: 0 }}
				ref={scrollRef}
				data-testid="SinglePageLayout-scrollable-content"
			>
				{children}
			</DialogContent>
		</Dialog>
	);
};

export default SinglePageLayout;
