import { gql } from '@apollo/client';

export const useWorkspaceFeatures_MeFragment = gql`
	fragment useWorkspaceFeatures_MeFragment on Me {
		id
		workspace {
			id
			features {
				name
				isActive
			}
		}
	}
`;
