import { useNavigate } from '@storis/app_common.router';
import { useCallback } from 'react';

const useRemoveLocationParams = () => {
	const navigate = useNavigate();
	return useCallback(
		(params?: string[]) => {
			if (params != null) {
				const queryParams = new URLSearchParams(window.location.search);
				params.forEach((param) => {
					queryParams.delete(param);
				});
				navigate(
					{ search: queryParams.toString(), hash: window.location.hash },
					{ replace: true },
				)?.catch(() => {});
			} else {
				navigate({ search: undefined, hash: window.location.hash }, { replace: true })?.catch(
					() => {},
				);
			}
		},
		[navigate],
	);
};

export default useRemoveLocationParams;
