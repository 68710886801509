import decodeToken from './decodeToken';

/**
 * Calculates how far off the device clock is, based on the token's iat property.\
 * Assumptions made here:
 *
 * - The server used an accurate timestamp for the iat property
 * - The token was transferred over the network relatively quickly (our default request timeout of 30s should be enough to
 *   ensure this)
 * - This function is called immediately after receiving the token from the server
 *
 * The returned value is a number representing how many seconds ahead of server time the device clock is. A negative
 * number means the device clock is behind server time.
 */
const calculateClockDriftFromToken = (jwt: string): number | null => {
	const decodedToken = decodeToken<{ iat: number }>(jwt);
	if (decodedToken == null) {
		return null;
	}

	// iat is a standard property that all jwt must have, so it is safe to assume it exists
	return Math.floor(Date.now() / 1000 - decodedToken.iat);
};

export default calculateClockDriftFromToken;
