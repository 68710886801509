import { useToggleSwitch } from '@storis/app_common.hooks';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Collapse,
	IconButton,
	Stack,
} from '@storis/app_common.ui/components';
import { ExpandMoreIcon } from '@storis/app_common.ui/icons';
import { useId } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import type { EmptyContentViewProps } from '../EmptyContentView';
import { EmptyContentView } from '../EmptyContentView';

interface UnexpectedErrorViewProps
	extends FallbackProps,
		Pick<EmptyContentViewProps, 'buttonText'> {
	onClick?: () => void;
	children?: React.ReactNode;
}

const UnexpectedErrorView = (props: UnexpectedErrorViewProps) => {
	const {
		buttonText,
		error,
		resetErrorBoundary,
		onClick,
		children = 'An unexpected error has prevented this feature from being presented',
	} = props;
	const labelId = useId();
	const [expanded, alert] = useToggleSwitch();
	return (
		<EmptyContentView
			disablePadding
			disableGutters
			labelId={labelId}
			buttonText={buttonText}
			onClick={onClick}
		>
			<Box mb={onClick != null ? 2 : 0}>
				<Alert
					severity="error"
					variant="standard"
					action={
						<IconButton
							onClick={alert.toggle}
							aria-label={expanded ? 'Collapse' : 'Expand'}
							color="error"
							sx={{
								// Flip the icon based on state (this follows what `Accordion` does with its icon)
								transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
								transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
							}}
						>
							<ExpandMoreIcon />
						</IconButton>
					}
					sx={{
						textAlign: 'initial',
						'& .MuiAlert-action': {
							/*
							 * If this component is rendered with `onClick` a button will be rendered beneath the alert
							 * We need to ensure that the reset button rendered within the `Collapse` is centered with that button
							 * To do that, we cut the left padding on the action and apply it to the everything in the `Alert` (except the reset button)
							 */
							pl: 0,
						},
					}}
				>
					<Box
						sx={{
							/*
							 * this is more stuff being done to center the reset button:
							 * - apply right padding to compensate for centering reset button
							 */
							pr: 1,
						}}
					>
						<AlertTitle>Something went wrong</AlertTitle>
						<Box sx={{ mb: 2 }}>{children}</Box>
					</Box>
					<Collapse in={expanded}>
						<Stack
							gap={2}
							sx={{
								/*
								 * this is more stuff being done to center the reset button:
								 * - apply right padding to compensate for centering reset button
								 * - apply bottom margin to maintain gap between elements, since this can't contain the reset button
								 */
								pr: 1,
								mb: 2,
							}}
						>
							{error.message != null ? (
								<Box>
									<AlertTitle>Error Message</AlertTitle>
									<Box
										component="pre"
										sx={{
											/*
											 * `pre` elements have a default margin, remove it
											 * this is a `pre` to be consistent with the `error.stack` display
											 */
											m: 0,
										}}
									>
										{error.message}
									</Box>
								</Box>
							) : null}
							{error.stack != null ? (
								<Box sx={{ display: 'grid' }}>
									<AlertTitle>Error Stack</AlertTitle>
									<Box component="pre" sx={{ overflowY: 'auto', maxHeight: 250, m: 0 }}>
										{error.stack}
									</Box>
								</Box>
							) : null}
						</Stack>
						<Button fullWidth onClick={resetErrorBoundary} color="error">
							Reset
						</Button>
					</Collapse>
				</Alert>
			</Box>
		</EmptyContentView>
	);
};

export default UnexpectedErrorView;
