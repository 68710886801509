import getStoredClockDrift from '../storage/getStoredClockDrift';
import decodeToken from './decodeToken';
import type { Jwt } from './types';

/** Determine whether a JWT is well-formed and has not expired */
const isAcceptable = (jwt: string): boolean => {
	const decodedJwt = decodeToken<Jwt>(jwt);
	if (decodedJwt == null) {
		// tokens that aren't decoded into an object are unacceptable
		return false;
	}

	// determine expiry, expired tokens are unacceptable
	// use of `exp` claim is optional: https://tools.ietf.org/html/rfc7519#section-4.1
	const { exp = null } = decodedJwt;
	if (exp == null) {
		return true;
	}

	// expiration is expressed in seconds since epoch
	// the processing of the `exp` claim requires that the current date/time is before the specified date/time
	const currentTime = new Date().getTime() / 1000;
	const clockDrift = getStoredClockDrift() ?? 0;
	return currentTime - clockDrift < exp;
};

export default isAcceptable;
