import { IconButton } from '@storis/app_common.ui/components';
import type { IconButtonProps } from '@storis/app_common.ui/components';
import { FileCopyIcon } from '@storis/app_common.ui/icons';

const CopyIconButton = (props: IconButtonProps) => (
	<IconButton
		sx={{ ml: 1, width: 'unset', height: 'unset' }}
		aria-label="Copy to Clipboard"
		{...props}
		size="large"
	>
		<FileCopyIcon sx={{ fontSize: 20 }} />
	</IconButton>
);

export default CopyIconButton;
