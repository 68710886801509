import { setContext } from '@apollo/client/link/context';

export interface MakeAuthLinkParams {
	getAccessToken: () => string | null | undefined;
}

// adapted from https://www.apollographql.com/docs/react/recipes/authentication
const makeAuthLink = ({ getAccessToken }: MakeAuthLinkParams) =>
	setContext((operation, prevContext): { headers: { Authorization: string } } => {
		const accessToken = getAccessToken();
		return {
			headers: {
				...prevContext.headers,
				...(accessToken != null &&
					!(prevContext.skipAuth as boolean | undefined) && {
						Authorization: `Bearer ${accessToken}`,
					}),
			},
		};
	});

export default makeAuthLink;
