import { jwtDecode } from 'jwt-decode';

/**
 * Decode JWT, null if the token cannot be decoded
 *
 * @todo [2025-02-28]: CXM-9275 Add zod schema validation logic instead of generic
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
const decodeToken = <TToken = Record<string, unknown>>(jwt: string): TToken | null => {
	try {
		const results = jwtDecode<TToken>(jwt);
		if (results == null || typeof results !== 'object') {
			// tokens that aren't decoded into an object are unacceptable
			return null;
		}
		return results;
	} catch {
		// unable to decode or determine expiry
		return null;
	}
};

export default decodeToken;
