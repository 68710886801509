import { useLazyQuery } from '@apollo/client';
import {
	getStoredIdentity,
	setStoredIdentity,
	setStoredWorkspace,
} from '@storis/app_common.utils/storage';
import type { Workspace } from '@storis/app_common.utils/storage';
import { useCallback, useMemo } from 'react';
import type { GetMeDataResult, GetMeDataVariables } from '#internal/types/graphqlTypes';
import { GetMeData } from './queries.gql';

const useGetMeDetails = () => {
	const [getMeData, { loading, called }] = useLazyQuery<GetMeDataResult, GetMeDataVariables>(
		GetMeData,
		{ context: { errorMessage: 'Unable to retrieve user details', infiniteRetry: true } },
	);

	const onGetMeData = useCallback(async () => {
		const { data } = await getMeData();
		const staffId = data?.me?.staff?.id;
		const userId = data?.me?.id;
		const email = data?.me?.email;
		const name = `${data?.me?.givenName} ${data?.me?.familyName}`;
		const workspace: Workspace = {
			...data?.me?.workspace,
			erpVersion: data?.me?.workspace?.erpDetails?.version,
			clientNumber: data?.me?.workspace?.erpDetails?.clientNumber,
			country: data?.me?.workspace?.country as Workspace['country'],
			clientId: data?.me?.workspace?.client?.id,
			patchLevel: data?.me?.workspace?.erpDetails?.patchLevel,
		};
		const identity = getStoredIdentity();
		setStoredIdentity({ ...identity, userId, staffId, email, name });
		setStoredWorkspace(workspace);
	}, [getMeData]);

	return useMemo(() => {
		return { getMeData: onGetMeData, loading, called };
	}, [loading, called, onGetMeData]);
};

export default useGetMeDetails;
