import { styled } from '@storis/app_common.ui/components';
import type { Breakpoint } from '@storis/app_common.ui/components';
import type { HTMLAttributes } from 'react';
import { ProgressContainer } from '../ProgressContainer';

export interface ContentLayoutProps extends HTMLAttributes<HTMLDivElement> {
	FAB?: React.ReactNode;
	children: React.ReactNode;
	/**
	 * `isLoading` (default `false`)\
	 * If `true` an absolutely positioned linear progressbar will be presented below the `Heading` (or at the top, if
	 * there is no `Heading`)
	 */
	isLoading?: boolean;
	/**
	 * `scrollableElementRef`\
	 * If specified, will be applied as the `ref` prop for the scrollable element containing the `children`
	 */
	scrollableElementRef?: React.Ref<HTMLDivElement>;
	/**
	 * `rightPanelPinnedBreakpoint` (default `undefined`)\
	 * The breakpoint at which the right panel will be pinned to the right side of the page\
	 * Should be specified when this layout is being used within a `RightPanelLayout` or other `PageContext` consumer
	 */
	rightPanelPinnedBreakpoint?: Breakpoint;
}

/** The layout's root element */
const ContentLayoutRoot = styled('div')({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	minWidth: 0,
	flexGrow: 1,
});

/** Scrollable element that is optionally padded to accommodate for the floating action button (FAB) */
const ScrollableContent = styled('div')(({
	hasFAB,
	rightPanelPinnedBreakpoint,
}: {
	hasFAB: boolean;
	rightPanelPinnedBreakpoint: ContentLayoutProps['rightPanelPinnedBreakpoint'];
}) => {
	return {
		flexGrow: 1,
		/**
		 * Make the content scrollable when there is a pinned right panel\
		 * If this element isn't scrollable, allowing `overflow: auto` will cut off box-shadows, preventing them from being
		 * rendered outside of this element
		 */
		overflow: rightPanelPinnedBreakpoint != null ? 'auto' : 'unset',
		WebkitOverflowScrolling: 'touch',
		/* Make room for the FAB */
		paddingBottom: hasFAB ? 88 : undefined,
	};
});

const ContentLayout = (props: ContentLayoutProps) => {
	const {
		FAB,
		children,
		isLoading = false,
		rightPanelPinnedBreakpoint,
		scrollableElementRef,
		...other
	} = props;

	return (
		<ContentLayoutRoot>
			<ScrollableContent
				ref={scrollableElementRef}
				hasFAB={FAB != null}
				rightPanelPinnedBreakpoint={rightPanelPinnedBreakpoint}
				{...other}
			>
				<ProgressContainer
					isLoading={isLoading}
					rightPanelPinnedBreakpoint={rightPanelPinnedBreakpoint}
				>
					{children}
				</ProgressContainer>
			</ScrollableContent>
			{FAB ?? null}
		</ContentLayoutRoot>
	);
};

export default ContentLayout;
