import { Box } from '@storis/app_common.ui/components';
import type { BoxProps } from '@storis/app_common.ui/components';

export interface ContentProps extends BoxProps {
	className?: string;
	disableGutters?: boolean;
	disablePadding?: boolean;
}

const Content = (props: ContentProps) => {
	const {
		children,
		className,
		disableGutters = false,
		disablePadding = false,
		sx,
		...other
	} = props;
	return (
		<Box
			className={className}
			sx={{
				px: disableGutters ? 0 : { xs: 2, sm: 3 },
				py: disablePadding ? 0 : { xs: 2, sm: 3 },
				...sx,
			}}
			{...other}
		>
			{children}
		</Box>
	);
};

export default Content;
