import type { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Auth } from '@storis/app_common-graphql.components/Auth';
import {
	useCreateNotification,
	useDismissNotification,
	useGetCurrentNotificationMessage,
} from '@storis/app_common.redux';
import { Outlet } from '@storis/app_common.router';
import getEnv from '#shared/utils/getEnv';
import HomeNotifications from './HomeNotifications';

const { turnstileSiteKey } = getEnv();
interface AppProps {
	pca: IPublicClientApplication;
}

const App = (props: AppProps) => {
	const { pca } = props;
	const createNotification = useCreateNotification();
	const dismissNotification = useDismissNotification();
	const getCurrentNotificationMessage = useGetCurrentNotificationMessage();

	return (
		<MsalProvider instance={pca}>
			<Auth
				appPath="/"
				createNotification={createNotification}
				dismissNotification={dismissNotification}
				getCurrentNotificationMessage={getCurrentNotificationMessage}
				turnstileSiteKey={turnstileSiteKey}
			>
				<Outlet />
			</Auth>
			<HomeNotifications />
		</MsalProvider>
	);
};

export default App;
