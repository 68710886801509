import {
	Card,
	CardContent,
	LinearProgress,
	Stack,
	Typography,
} from '@storis/app_common.ui/components';
import { useId } from 'react';

interface SignInCardProps {
	title: string;
	children: React.ReactNode;
	loading?: boolean;
}

const SignInCard = (props: SignInCardProps) => {
	const { title, children, loading } = props;

	const cardTitleId = useId();

	return (
		<Card
			sx={{ maxWidth: 444, flexGrow: 1, position: 'relative' }}
			role="region"
			aria-labelledby={cardTitleId}
		>
			{loading && <LinearProgress sx={{ position: 'absolute', left: 0, right: 0 }} />}
			<CardContent>
				<Stack gap={2}>
					<img
						src="https://storisstaticassetsprod.blob.core.windows.net/images/logos/Blue-STORIS-NextGen-Logo.png"
						alt="STORIS NextGen"
						height={51}
						width={128}
						style={{ width: 128 }}
					/>
					<Typography variant="h6" id={cardTitleId}>
						{title}
					</Typography>
					{children}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default SignInCard;
