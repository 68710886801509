import { useMutation } from '@apollo/client';
import { Turnstile } from '@marsidev/react-turnstile';
import { ExternalPageLayout } from '@storis/app_common.components';
import { useDocumentTitle } from '@storis/app_common.hooks';
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	LinearProgress,
	Stack,
	Typography,
} from '@storis/app_common.ui/components';
import { useId, useMemo, useState } from 'react';
import type {
	CancelPasswordResetResult,
	CancelPasswordResetVariables,
} from '#internal/types/graphqlTypes';
import { CancelPasswordReset } from './mutations.gql';

interface PasswordResetCancelProps {
	turnstileSiteKey: string;
	workspaceId: string;
	requestId: string;
	token: string;
}

const PasswordResetCancel = (props: PasswordResetCancelProps) => {
	const { turnstileSiteKey, workspaceId, requestId, token } = props;

	useDocumentTitle('Cancel Password Reset Request');
	const cardTitleId = useId();
	const [showInteractiveErrorMessage, setShowInteractiveErrorMessage] = useState(false);
	const [turnstileResponse, setTurnstileResponse] = useState<string | null>(null);
	const [requestStatus, setRequestStatus] = useState<
		'pending' | 'interaction-required' | 'challenge-failed' | 'request-failed' | 'success'
	>('pending');

	const cardTitle = useMemo(() => {
		switch (requestStatus) {
			case 'pending':
				return 'Canceling Request...';
			case 'interaction-required':
				return 'Cancel Request';
			case 'challenge-failed':
				return 'Cancel Request';
			case 'request-failed':
				return 'Request Cancelation Failed';
			case 'success':
				return 'Request Canceled Successfully';
			default:
				return null;
		}
	}, [requestStatus]);

	const errorMessage = useMemo(() => {
		if (showInteractiveErrorMessage && requestStatus === 'interaction-required') {
			return 'Human verification required';
		}

		if (requestStatus === 'challenge-failed') {
			return 'Human verification failed';
		}

		return null;
	}, [requestStatus, showInteractiveErrorMessage]);

	const bodyMessage = useMemo(() => {
		switch (requestStatus) {
			case 'interaction-required':
				return 'Please complete the human verification below to proceed.';
			case 'challenge-failed':
				return 'Please complete the human verification below to proceed.';
			case 'request-failed':
				return 'There was an issue while attempting to cancel the request.';
			case 'pending':
			case 'success':
				return null;
			default:
				// TODO [2025-01-31]: CXM-9285 UnreachableCaseError
				return null;
		}
	}, [requestStatus]);

	const [cancelRequest] = useMutation<CancelPasswordResetResult, CancelPasswordResetVariables>(
		CancelPasswordReset,
	);

	const processCancelRequest = (challengeResponse: string) => {
		if (requestStatus === 'pending' || requestStatus === 'request-failed') {
			cancelRequest({ variables: { workspaceId, requestId, token, challengeResponse } })
				.then(() => {
					setRequestStatus('success');
				})
				.catch(() => {
					setRequestStatus('request-failed');
				});
		}
	};

	return (
		<ExternalPageLayout>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Card sx={{ maxWidth: 444, flexGrow: 1 }} role="region" aria-labelledby={cardTitleId}>
					{requestStatus === 'pending' && <LinearProgress />}
					<CardContent>
						<Stack gap={2}>
							{cardTitle != null && (
								<Typography variant="h6" component="h2" id={cardTitleId}>
									{cardTitle}
								</Typography>
							)}
							{errorMessage != null && <Typography color="error.main">{errorMessage}</Typography>}
							{bodyMessage != null && <Typography>{bodyMessage}</Typography>}
							<Box
								sx={{
									display:
										requestStatus === 'interaction-required' || requestStatus === 'challenge-failed'
											? 'block'
											: 'none',
								}}
							>
								<Turnstile
									siteKey={turnstileSiteKey}
									onSuccess={(value) => {
										setTurnstileResponse(value);
										processCancelRequest(value);
									}}
									onBeforeInteractive={() => {
										setRequestStatus('interaction-required');
									}}
									options={{ theme: 'light', size: 'normal' }}
								/>
								{requestStatus === 'interaction-required' && showInteractiveErrorMessage && (
									<Typography color="error.main" variant="body2" sx={{ mt: 1 }}>
										Please click the checkbox for verification
									</Typography>
								)}
							</Box>
							{requestStatus === 'success' && (
								<Alert severity="success">Create new password request canceled</Alert>
							)}
							{(requestStatus === 'interaction-required' ||
								requestStatus === 'challenge-failed') && (
								<Button
									variant="contained"
									onClick={() => {
										if (requestStatus === 'interaction-required') {
											setShowInteractiveErrorMessage(true);
										}
										// This button will do nothing if the challenge had failed
									}}
								>
									Proceed
								</Button>
							)}
							{requestStatus === 'request-failed' && (
								<Button
									variant="contained"
									onClick={() => {
										if (turnstileResponse != null) {
											setRequestStatus('pending');
											processCancelRequest(turnstileResponse);
										}
									}}
								>
									Retry
								</Button>
							)}
						</Stack>
					</CardContent>
				</Card>
			</Box>
		</ExternalPageLayout>
	);
};

export default PasswordResetCancel;
